import React, { useEffect, useState, useRef } from "react";
import {
  differenceInSeconds} from "date-fns";

import { Howl, Howler } from "howler";
import { Icon } from "primitives";
import { eventBus } from "../helpers/eventBus";


export const AlertSoundPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const lastPlayedTimestampRef = useRef<string | null>(null);
    const [isMuted, setIsMuted] = useState(false);
    const alertSoundRef = useRef<Howl | null>(null);
    const checkForLastSeconds = 10;
  
    useEffect(() => {
      alertSoundRef.current = new Howl({
        src: ["/alert.mp3"],
        volume: 0.3,
        sprite: {
          alert: [0, 1000]
        },
        onplay: () => setIsPlaying(true),
        onend: () => setIsPlaying(false)
      });
  
      const handleAlert = (alerts: any[]) => {
        const now = new Date();
  
        alerts.forEach((alert) => {
          const diffSeconds = differenceInSeconds(now, alert?.receiveTimestamp);
          const shouldPlay = shouldPlayAlert(diffSeconds, checkForLastSeconds, isPlaying, alertSoundRef);
          const shouldSkip = shouldSkipAlert(alert, lastPlayedTimestampRef.current);
  
          if ( !shouldSkip && shouldPlay ) {
            alertSoundRef.current!.play("alert");
            console.log(`alert played for "${alert?.dataSourceName}" at ${alert?.receiveTimestamp}`);
            lastPlayedTimestampRef.current = alert?.receiveTimestamp;
          }
        });
      };
  
      eventBus.on("alert-sound", handleAlert);
      return () => eventBus.off("alert-sound", handleAlert);
    }, []);
  
    const toggleMute = () => {
      const muteState = !isMuted;
      Howler.mute(muteState);
      setIsMuted(muteState);
    };
    return (
      <div
        className={`sound-alert-icon ${isPlaying ? "animate" : ""}`}
        onClick={toggleMute}
        style={{ cursor: "pointer" }}
        title={isMuted ? "Unmute alerts" : "Mute alerts"}
      >
        <Icon name={"Bell"} size={20} muted={isMuted} />
      </div>
    );
  };
  
  export const shouldSkipAlert = (
    alert: any,
    lastPlayedTimestamp: string | null
  ): boolean => {
    if (!lastPlayedTimestamp) {
      return false;
    }
    const alertTimeISO = alert?.receiveTimestamp;
    if (!alertTimeISO || typeof alertTimeISO !== "string") {
      return true;
    }
  
    const alertTime = new Date(alertTimeISO.split(".")[0]);
    const lastPlayed = new Date(lastPlayedTimestamp.split(".")[0]);
  
    return alertTime <= lastPlayed;
  };
  
  export const shouldPlayAlert = (
    diffSeconds: number,
    checkForLastSeconds: number,
    isPlaying: boolean,
    alertSoundRef: React.MutableRefObject<Howl | null>
  ): boolean => {
    return (
      diffSeconds >= 0 &&
      diffSeconds <= checkForLastSeconds &&
      alertSoundRef.current !== null &&
      !isPlaying
    );
  };