import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alerts } from "../components/Alerts";
import {
  getAlerts,
  dismissAlerts,
  dismissAllAlerts,
  getAlertsDataSourceList,
  setAlerts
} from "../services";
import { SelectedAlert, AlertDTO } from "../models";

const mapStateToProps = (state: any) => ({
  satellites: state.constellations.dashboard,
  dismissAlertsState: state.alert.alertDismiss,
  alertCounter: state.alert.alertCounter,
  alerts: state.alert.alerts
});

const mapDispatchToProps = () => {
  return {
    getAlerts: (
      satelliteId: number,
      pageSize: number,
      paginationUrl?: string,
      dismissed = "false",
      datasources: number[] | null | undefined = null
    ) =>
      getAlerts(satelliteId, pageSize, paginationUrl, dismissed, datasources),
    getAlertsDataSourceList: (satelliteId: number) =>
      getAlertsDataSourceList(satelliteId),
    dismissAlerts: (
      satelliteId: number,
      alertIds: SelectedAlert[],
      pageSize: number,
      datasources: number[] | null | undefined = null
    ) => dismissAlerts(satelliteId, alertIds, pageSize, datasources),
    dismissAllAlerts: (
      satelliteId: number,
      datasourceId: number | null | undefined = null
    ) => dismissAllAlerts(satelliteId, datasourceId),
    setAlerts: (alerts: AlertDTO[]) => setAlerts(alerts),

  };
};

export const AlertsContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Alerts)
);
