import React from "react";
import {
  KeyStatus,
  EncryptionKey,
  ChangeStateDialog,
  IContextActions,
  ContextActionsNames,
  Action
} from "../models";

export const encryptionKeyStateManager = (
  encryptionKey: number,
  encryptionKeys: EncryptionKey[],
  status: KeyStatus
): EncryptionKey[] => {
  switch (status) {
    case KeyStatus.ACTIVE:
      return encryptionKeys.map((mk) => {
        return (
          (mk.key_id === encryptionKey && {
            ...mk,
            status
          }) ||
          mk
        );
      });

    case KeyStatus.DESTROYED:
      return encryptionKeys.filter((mk) => mk.key_id !== encryptionKey);
    default:
      return encryptionKeys;
  }
};

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const handleKeyStateDialog = (
  e: React.MouseEvent,
  key: EncryptionKey,
  state: KeyStatus,
  contextDispatcher: React.Dispatch<IContextActions>
) => {
  e.stopPropagation();
  const payload: ChangeStateDialog = {
    key,
    isOpen: true,
    setOpen: contextDispatcher,
    state
  };
  contextDispatcher({ type: ContextActionsNames.changeStateDialog, payload });
};

export const getActionStatus = (label: string, state: KeyStatus): boolean => {
  switch (label) {
    case Action.ACTIVATE:
      return (
        state === KeyStatus.PRE_OPERATIONAL || state === KeyStatus.SUSPENDED
      );
    case "Suspend":
      return state === KeyStatus.ACTIVE || state === KeyStatus.SUSPENDED;
    case "Deactivate":
      return state === KeyStatus.SUSPENDED;
    case "Destroy":
      return (
        state === KeyStatus.PRE_OPERATIONAL || state === KeyStatus.DEACTIVATED
      );
    default:
      return true;
  }
};
