import React from "react";
import { themeGet } from "styled-system";
import styled from "styled-components";
import { Box, Flex, Text, Icon, Image } from "primitives";
import { DateTimeLabel, Popover } from "components";
import { ConstellationSatelliteSelector } from "app/constellation";
import { theme } from "styles";
import { Logout } from "app/auth";
import { store } from "app/store";
import { AlertCounter } from "app/alert";
import { PassagesSelectorContainer } from "app/visibilityWindow";
import config from "config/constants";
import { fetchServerTime, tickTime } from "app/visibilityWindow/actions";
import { useSelector, useDispatch } from "react-redux";
import { useInterval } from "app/visibilityWindow/helpers";
import Keycloak from "keycloak-js";
import { AlertSoundPlayer } from "app/alert/components/AlertSoundPlayer";

const NavbarItem = styled(Flex)``;
NavbarItem.defaultProps = {
  alignItems: "center",
  borderLeft: 2,
  borderRight: 0,
  borderColor: "palette.brand.0",
  px: 2
};

const ProfileDropdown = (
  <Flex
    py={1}
    flexDirection="column"
    bg="fill.0"
    color="text.default"
    minWidth="100px"
    fontSize={3}
    data-testid="ProfileDropdown"
  >
    <Box p={2}>
      <Logout data-testid="Logout" kc={new Keycloak()}>
        <Text as="div" center caps>
          Logout
        </Text>
      </Logout>
    </Box>
  </Flex>
);

const Navbar = ({ showSideBar, toggleSideBar }: any) => {
  const dispatch = useDispatch();
  const serverTime = useSelector(
    (state: any) => state.visibilityWindow.serverTime
  );
  const serverTimeInUTC = new Date(
    serverTime.getTime() + serverTime.getTimezoneOffset() * 60000
  );

  useInterval(
    () => dispatch(fetchServerTime()),
    config.timer.serverTimeSyncDuration
  );

  useInterval(() => dispatch(tickTime()), config.timer.clock);

  const height = themeGet("sizes.navbarHeight")({ theme });
  return (
    <Flex
      height={height}
      color="text.default"
      alignItems="stretch"
      borderBottom={3}
      borderColor="border.1"
      bg="fill.3"
      pl={2}
      data-testid="Navbar"
    >
      <Flex padding={1} minWidth="100px" flex="1 0 auto" alignItems="center">
        <Icon name="Logo" color="palette.white.0" />
        <Flex display={{ sm: "flex", md: "none", lg: "flex" }}>
          <Text fontSize={4} caps pl={2}>
            Mission control platform
          </Text>
        </Flex>
      </Flex>
      <Flex display={{ xs: "none", md: "flex" }}>
        <NavbarItem px={1} overflow="visible">
          <AlertSoundPlayer />
        </NavbarItem>
        <NavbarItem px={1} overflow="visible">
          <AlertCounter />
        </NavbarItem>
        <NavbarItem px={0} minWidth="205px" alignItems="stretch">
          <ConstellationSatelliteSelector />
        </NavbarItem>
        <NavbarItem minWidth="175px" justifyContent="center">
          <PassagesSelectorContainer />
        </NavbarItem>
        <NavbarItem minWidth="230px" justifyContent="center">
          <Flex alignItems="center">
            <DateTimeLabel
              value={serverTimeInUTC}
              format="short"
              fontSize={4}
            />
            <Text px={1}>::</Text>
          </Flex>
          <Flex
            pb={"2px"}
            width="82px"
            justifyContent="center"
            overflow="visible"
          >
            <DateTimeLabel
              value={serverTimeInUTC}
              format="time"
              fontSize={7}
              color="palette.white.0"
            />
          </Flex>
          <Text pb={"2px"} pl={1} fontSize={7} caps>
            utc
          </Text>
        </NavbarItem>

        <Popover overlay={ProfileDropdown} placement="bottom-end">
          <NavbarItem>
            <Image size={20} src="/static/Aurora.png" />
            <Text pl={2} fontSize={2} color="palette.white.0" caps>
              {store.getState().keycloak.idTokenParsed?.name}
            </Text>
            <Box ml={2} p={1} border={3} borderRadius="50%" overflow="visible">
              <Icon name="ArrowDown" size={6} />
            </Box>
          </NavbarItem>
        </Popover>
      </Flex>
    </Flex>
  );
};

Navbar.displayName = "Navbar";

export default Navbar;
