import React, { useReducer, useEffect, useRef, useCallback } from "react";
import { Heading, Flex } from "primitives";
import { BackButton } from "components";
import {
  PlanningSystemTimeline,
  DrawerContent
} from "app/planningSystem/containers";
import { OperationActivityDialog } from "app/planningSystem/containers";
import { TimelineDisapprove, TimelineItemDialog } from "./TimelineDialog";
import { NextEvents } from "./NextEvents";
import styled from "styled-components";
import { NavigationDrawer } from "components";
import {
  initialState,
  reducer,
  PlanningSystemContext
} from "app/planningSystem/context";
import {
  ContextActionsNames,
  PlanningSystemProps,
  TimelineTypes,
  FetchAllTimelineResponse,
  ITimeline
} from "app/planningSystem/models";
import { useSelector } from "react-redux";
import { getEarliestTimelineEntry } from "app/planningSystem/utils/helpers";

const Container = styled("div")`
  background-color: #2e4162;
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;
const PlanningSystemRoot = styled("div")`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const HeightFiller = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const PlanningSystem = ({
  getTimeline,
  getAllPassages,
  getCalendarTimeline,
  getAllOperationActivities,
  getPastTimelines
}: PlanningSystemProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    navigationDrawerVisible,
    contextMenu,
    selectedSatellites,
    timelineWindow,
    selectedTimeline
  } = state;

  const masterTimeline: ITimeline = useSelector(
    (s: any) =>
      s.planningSystem.timelines?.timelines.find(
        (t: ITimeline) => t.timelineType === TimelineTypes.MASTER
      )
  );

  const page = useRef<number>(1);

  useEffect(() => {
    getAllOperationActivities();
    getTimelineAsync();
  }, []);

  useEffect(() => {
    if (selectedSatellites.length > 0) {
      getAllPassages(
        selectedSatellites.map((s) => Number(s)),
        timelineWindow.startTime,
        timelineWindow.endTime
      );
    }
  }, [selectedSatellites]);

  const handleNavigationDrawer = (show = navigationDrawerVisible) => {
    dispatch({
      type: ContextActionsNames.navigationDrawerVisible,
      payload: !show
    });
  };

  const getTimelineAsync = async () => {
    const timelines = await getTimeline(
      selectedTimeline?.timelineType || TimelineTypes.MASTER
    );
    if (timelines) {
      getCalendarTimeline(timelines);
    }
  };

  const getPastTimeline = async (begin: Date, end: Date) => {
    try {
      // Getting the earliest master timeline entry
      const earliestEntry = getEarliestTimelineEntry(masterTimeline.entries);
      if (!earliestEntry) return null;

      // Checking if the timeline has been scrolled before the earliest timeline entry
      if (begin.toISOString() >= earliestEntry?.executionStart) return null;

      // Getting the paginated PAST timeline
      await getPastTimelines({
        page: page.current,
        timeline_type: TimelineTypes.PAST
      });

      // After each request increase the pagination
      page.current = page.current + 1;
    } catch (error) {
      console.log("🚀 ~ error:", error);
    }
  };

  const dataProvider = async (begin?: Date, end?: Date) => {
    if (begin && end) {
      await dispatch({
        type: ContextActionsNames.timelineWindow,
        payload: { startTime: begin, endTime: end }
      });
      await getPastTimeline(begin, end);
      if (selectedSatellites.map((s) => Number(s)).length > 0)
        await getAllPassages(
          selectedSatellites.map((s) => Number(s)),
          begin,
          end
        );
    }
  };

  return (
    <PlanningSystemContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      <PlanningSystemRoot data-testid={"planning-system-root"}>
        <Heading display={1} marginBottom={4}>
          Planning System
        </Heading>
        <Container>
          <Heading display={2} marginBottom={4}>
            Timeline
          </Heading>
          <HeightFiller>
            <Flex justifyContent={"end"} mb={1} alignItems={"start"}>
              <BackButton onClick={() => handleNavigationDrawer()}>
                Operation Activity Manager
              </BackButton>
            </Flex>
            {state.selectedTimeline && (
              <NextEvents selectedTimeline={state.selectedTimeline} />
            )}
            <PlanningSystemTimeline dataProvider={dataProvider} />
          </HeightFiller>
        </Container>
        <NavigationDrawer
          visible={navigationDrawerVisible}
          onClose={handleNavigationDrawer}
          heading={"Operation activity manager"}
        >
          <DrawerContent />
        </NavigationDrawer>
        <OperationActivityDialog />
        <TimelineItemDialog />
        <TimelineDisapprove />
        {contextMenu}
      </PlanningSystemRoot>
    </PlanningSystemContext.Provider>
  );
};
