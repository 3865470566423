import Ajv from "ajv";
import { GET_ONE, GET_LIST, UPDATE_ONE, CREATE, DELETE } from "app/network";
import { dataProvider } from "app/network";
import * as actions from "../actions";
import { store } from "app/store";
import { configOptionsSchemaBuilder } from "app/dashboard";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { clone } from "utils";
import {
  convertStylesColorsToHex,
  convertValueMappingColorsToHex
} from "../utils/transformations";

export const getUserDashboardsLists = (satelliteDefinitionId) => {
  return dataProvider(
    GET_LIST,
    `dashboard/user-dashboards/${satelliteDefinitionId}/list`,
    {}
  );
};

export const updateUserDashboardsLists = (satelliteDefinitionId, data) => {
  return dataProvider(
    CREATE,
    `dashboard/user-dashboards/${satelliteDefinitionId}/list`,
    {
      data: data
    }
  );
};

export const getUserDashboards = (satelliteDefinitionId) => {
  return dataProvider(
    GET_LIST,
    `dashboard/user-dashboards/${satelliteDefinitionId}`,
    {}
  );
};

export const getUserDashboard = (dashboardId) => {
  return dataProvider(GET_ONE, `dashboard`, {
    id: dashboardId
  }).then((response) => {
    return response.data;
  });
};

export const editDashboard = async (dashboardId, dashboardDefinition, idx) => {
  // NOTE: update the component definition so that it conforms with what the server needs.
  // this will be removed as soon as the front-end implements this feature
  let transformedDashboardDefinition;

  if (idx || idx === 0) {
    const originalDashboard = await getUserDashboard(dashboardId);

    delete originalDashboard.applicableSatDefinition;
    delete originalDashboard.id;
    delete originalDashboard.ownerId;
    originalDashboard.satDefinitionId =
      dashboardDefinition.applicableSatDefinition.id;
    originalDashboard.components[idx].configOptions =
      dashboardDefinition.components[idx].configOptions;

    transformedDashboardDefinition = transformDashboardDefinition(
      originalDashboard,
      idx
    );
  } else {
    transformedDashboardDefinition =
      transformDashboardDefinition(dashboardDefinition);
  }

  const response = await dataProvider(UPDATE_ONE, `dashboard/${dashboardId}`, {
    data: transformedDashboardDefinition
  });
  if (idx || idx === 0) {
    // to keep displaying the widgets with changes in size and position after saving config change
    store.dispatch(actions.updateDashboard(dashboardDefinition));
  } else {
    store.dispatch(actions.updateDashboard(response.data));
  }
  return response.data;
};

export const createDashboard = (dashboardDefinition) => {
  // NOTE: update the component definition so that it conforms with what the server needs.
  // this will be removed as soon as the front-end implements this feature
  const transformedDashboardDefinition =
    transformDashboardDefinition(dashboardDefinition);
  return dataProvider(CREATE, `dashboard`, {
    data: transformedDashboardDefinition
  });
};

export const deleteUserDashboard = (dashboardId) => {
  const data = {
    id: dashboardId
  };
  return dataProvider(DELETE, `dashboard`, data).then(() => {
    store.dispatch(actions.removeDashboard(data));
    return data;
  });
};

export const addDashboards = (dashboards) =>
  store.dispatch(actions.addDashboards(dashboards));

const transformDashboardDefinition = (
  originalDashboardDefinition,
  componentIndex = null
) => {
  // update the components with a default label and transform component configOptions
  const updatedComponents = originalDashboardDefinition.components.map(
    (it, index) => {
      let updatedComponent = clone(it);

      if (!updatedComponent.label) {
        updatedComponent.label = "";
      }

      if (
        updatedComponent.configOptions == null &&
        configOptionsSchemaBuilder(updatedComponent.type)
      ) {
        updatedComponent.configOptions = configOptionsSchemaBuilder(
          updatedComponent.type
        ).default;
      }

      const updatedDataSources = [];
      const newConfigOptionsDataSources = [];
      it.dataSources.forEach((dataSource) => {
        updatedDataSources.push({
          datasourceId: dataSource.datasourceId,
          label: dataSource.label
        });

        //Setup configOptions.dataSource and configOptions.dataSources options
        if (updatedComponent.configOptions) {
          if (updatedComponent.configOptions.dataSources) {
            if (dataSource.indexes) {
              dataSource.indexes = dataSource.indexes.map((index) => {
                //For now just the index number field is being sent
                //TODO: add remaining index fields (ex: see Graphs schema)
                return { index: !isNaN(index) ? index : index.index };
              });
            }
            const existingDataSource =
              updatedComponent.configOptions.dataSources.find(
                (ds) => ds.id === dataSource.datasourceId
              );
            newConfigOptionsDataSources.push({
              id: dataSource.datasourceId,
              name: dataSource.name
                ? dataSource.name
                : existingDataSource
                ? existingDataSource.name
                : null,
              label: dataSource.label
                ? dataSource.label
                : existingDataSource
                ? existingDataSource.label
                : null,
              indexes: dataSource.indexes
                ? dataSource.indexes
                : existingDataSource
                ? existingDataSource.indexes
                : null,
              unit: dataSource.unit
                ? dataSource.unit
                : existingDataSource
                ? existingDataSource.unit
                : null
            });
          }
        }
      });

      updatedComponent.dataSources = updatedDataSources;
      if (
        updatedComponent.configOptions &&
        updatedComponent.configOptions.dataSources
      ) {
        updatedComponent = {
          ...updatedComponent,
          configOptions: {
            ...updatedComponent.configOptions,
            styles:
              it.type === "Label"
                ? convertStylesColorsToHex(
                    updatedComponent.configOptions.styles
                  )
                : updatedComponent.configOptions.styles,
            valueMapping:
              it.type === "Label"
                ? convertValueMappingColorsToHex(
                    updatedComponent.configOptions.valueMapping
                  )
                : updatedComponent.configOptions.valueMapping,
            dataSources: newConfigOptionsDataSources
          }
        };
      }

      //Validate if configOptions are valid using a JSON schema validator
      const configOptions = configOptionsSchemaBuilder(updatedComponent.type);
      if (
        configOptions &&
        configOptions.schema &&
        (componentIndex === null || componentIndex === index)
      ) {
        /*
        TODO: update Ajv to latest version and retry schema validation
        Temp fix to avoid breaking frontend if validation fails
        */
        const ajv = new Ajv();
        const validate = ajv.compile(configOptions.schema);
        // eslint-disable-next-line
        const valid = validate(updatedComponent.configOptions);
        if (!valid) {
          console.log(
            "Schema validation failed:",
            updatedComponent.type,
            "validate.errors",
            validate.errors
          );
          // store.dispatch(
          //   setFeedback(
          //     "Schema validation failed",
          //     FeedbackStatus.WARNING,
          //     `${updatedComponent.type}: ${validate.errors.map(
          //       (e) => e.message
          //     )}`
          //   )
          // );
        }
      }
      return updatedComponent;
    }
  );

  const transformedDashboardDefinition = {
    ...originalDashboardDefinition,
    components: updatedComponents
  };
  return transformedDashboardDefinition;
};
