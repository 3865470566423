import {
  Result,
  SimpleSatellite,
  SimpleGroundStation,
  GroupedBySatelliteID
} from "../models";

interface GroundStation {
  groundStationID: number;
  groundStationName: string;
}

type GroundStationData = Record<string, GroundStation[]>;

const createGroundStationLookup = (
  data: GroundStationData
): Record<string, number> =>
  Object.values(data)
    .flat()
    .reduce(
      (lookup, { groundStationID, groundStationName }) => ({
        ...lookup,
        [groundStationName]: groundStationID
      }),
      {}
    );

export const transformFormData = (
  formData: any,
  satellites: any,
  groundStationConfigs?: any
) => {
  const nameIdMap = createGroundStationLookup(groundStationConfigs);
  const groundStationsBySatellite = formData?.groundStationsBySatellite.map(
    (sat: any) => {
      return {
        [findSatId(satellites, sat.satellite.replace(/\s?\[\d+\]/, ''))]: sat.groundStations
          .map((gs: any) => {
            const groundStationName = gs?.groundStationName || gs;
            return nameIdMap[groundStationName];
          })
          .filter(Boolean)
      };
    }
  );

  const transformedFormData = {
    name: formData.name,
    description: formData?.description,
    startTime: formData?.daterange?.from,
    endTime: formData?.daterange?.to,
    groundStationsBySatellite: Object.assign({}, ...groundStationsBySatellite),
    strategyId: 1
  };
  return transformedFormData;
};

const createSatIdLookupObj = (groundStationConfigs: Record<string, any>): Record<string, number> => {
  const entries = Object.keys(groundStationConfigs).map((key: string) => {
    const name = key.replace(/\s?\[\d+\]/, '');
    const number = parseInt(key.match(/\[(\d+)\]/)?.[1] || '', 10);
    return [name, number];
  });
  return Object.fromEntries(entries);
};


export function processFormData(data: any, groundStationConfigs: any) {
  const nameID = createSatIdLookupObj(groundStationConfigs)
  const config: any = {};
  config.name = data?.name;
  config.description = data?.description;
  config.provider = data?.strategyId;
  config.daterange = {
    from: data?.startTime,
    to: data?.endTime
  };
  config.additionalData = {};
  const sats = data?.groundStationsBySatellite
    ? Object.keys(data?.groundStationsBySatellite)
    : [];

  config.groundStationsBySatellite = sats?.map((satName: string) => {
    const selectedGroundStations = data?.groundStationsBySatellite[satName];
    return {
      satellite: `${satName} [${nameID[satName]}]`,
      groundStations: selectedGroundStations.map(
        (gs: any) => gs.groundStationName
      )
    };
  });
  return config;
}

function findGsIdFromName(groundStations: any[], groundStationName: string) {
  return groundStations.find((gs) => gs.groundStationName === groundStationName)
    ?.groundStationID;
}

function findSatId(satellites: any[], satellite: string) {
  return satellites.find((sat) => sat.label === satellite).id;
}

export function injectNamesFromIds(
  result: Result[],
  satellites: SimpleSatellite[]
): Result[] | void {
  if (!result || !Array.isArray(result)) return;

  return result
    .map((r: Result) => {
      const idsToNamesMapped = Object.entries(
        r.groundStationsBySatellite
      ).reduce<Record<string, SimpleGroundStation[]>>(
        (acc, [satId, groundStations]) => {
          const satellite = satellites.find(
            (s: SimpleSatellite) => s.id === Number(satId)
          );
          if (satellite) {
            acc[satellite.label] = groundStations as SimpleGroundStation[];
          } else {
            acc[satId] = groundStations as SimpleGroundStation[];
          }
          return acc;
        },
        {}
      );

      return {
        ...r,
        groundStationsBySatellite: idsToNamesMapped,
        groundStationsBySatelliteIDs: r.groundStationsBySatellite
      };
    })
    .sort((a, b) => b.id - a.id);
}

export function getSatsFromStore(state: any, sats: any[] = []) {
  const list = state()?.constellations?.dashboard?.length
    ? state()?.constellations?.dashboard
    : sats[0]?.satelliteInstances;
  const satellites =
    list?.map((s: any) => ({ id: s?.id, label: s?.label })) || [];
  return satellites;
}

export function getGroundStationsFromStore(state: any, gs: any[] = []) {
  const list = state()?.groundStations?.groundStations?.length
    ? state()?.groundStations?.groundStations
    : gs;
  const groundStations =
    list?.map((gs: any) => ({
      id: gs.groundStationID,
      name: gs.groundStationName
    })) || [];
  return groundStations;
}

export function findGSId(groundStations: any[], groundStationId: number) {
  return (
    groundStations.find((gs) => gs.id === groundStationId)?.name ||
    groundStationId
  );
}

export function findSatName(satelliteID: any, satellites: any) {
  return (
    satellites.find((sat: any) => sat.id === satelliteID)?.label || satelliteID
  );
}

export function getFromLocalStorage(KEY: string) {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) {
      return [];
    }
    const parsed = JSON.parse(serializedState);
    return parsed;
  } catch (e) {
    return undefined;
  }
}

export function saveInLocalStorage(KEY: string, state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    console.log("TURBO ~ saveState ~ e", e);
  }
}

export function clearState(KEY: string) {
  return localStorage.removeItem(KEY);
}

export const validator = (formData: any, errors: any) => {
  const now = new Date();
  if (formData.daterange) {
    const { from, to } = formData.daterange;
    if (from && new Date(from) < now) {
      errors.daterange.from.addError("date cannot be in the past.");
    }
    if (to && new Date(to) < now) {
      errors.daterange.to.addError("date cannot be in the past.");
    }
  }
  return errors;
};

export function isEmptyValue(value: any): boolean {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (typeof value === "object" &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0)
  );
}

interface FormattedData {
  [key: string]: any;
}
export function mergeFormData(
  formData: FormattedData,
  formattedData: FormattedData
): FormattedData {
  return Object.entries(formData).reduce((acc: FormattedData, [key, value]) => {
    acc[key] = isEmptyValue(value) ? formattedData[key] : value;
    return acc;
  }, {});
}

export enum QuickRange {
  OneDay = "1 day",
  ThreeDays = "3 days",
  OneWeek = "1 week",
  TwoWeeks = "2 weeks",
  ThreeWeeks = "3 weeks",
  OneMonth = "1 month",
  TwoMonths = "2 months",
  ThreeMonths = "3 months"
}

export const calculateToDate = (quickrange: QuickRange): Date => {
  const now = new Date();

  const dateAdditions: Record<QuickRange, (date: Date) => Date> = {
    [QuickRange.OneDay]: (date) => new Date(date.setDate(date.getDate() + 1)),
    [QuickRange.ThreeDays]: (date) =>
      new Date(date.setDate(date.getDate() + 3)),
    [QuickRange.OneWeek]: (date) => new Date(date.setDate(date.getDate() + 7)),
    [QuickRange.TwoWeeks]: (date) =>
      new Date(date.setDate(date.getDate() + 14)),
    [QuickRange.ThreeWeeks]: (date) =>
      new Date(date.setDate(date.getDate() + 21)),
    [QuickRange.OneMonth]: (date) =>
      new Date(date.setMonth(date.getMonth() + 1)),
    [QuickRange.TwoMonths]: (date) =>
      new Date(date.setMonth(date.getMonth() + 2)),
    [QuickRange.ThreeMonths]: (date) =>
      new Date(date.setMonth(date.getMonth() + 3))
  };

  return dateAdditions[quickrange]?.(now) || now;
};

export const isFetching = (
  status: {
    [key: string]: string | null;
  },
  checkSync = true
): boolean => {
  const fetching =
    Object.values(status).includes("loading") ||
    Object.values(status).some((value) => value !== "end") ||
    (checkSync && status?.sync !== "end");
  return fetching;
};

export const paginate = (page = 1, pageSize = 10) => {
  const validPage = Math.max(1, page);
  const validPageSize = Math.max(1, pageSize); 

  const startIndex = (validPage - 1) * validPageSize;
  const endIndex = startIndex + validPageSize; 

  return [startIndex, endIndex];
};

export function groupBySatelliteID(
  groundStationConfigs: any[]
): GroupedBySatelliteID {
  const groupedBySatellites: GroupedBySatelliteID = {};
  groundStationConfigs.forEach((config) => {
    const { satelliteName, satelliteID } = config;
    const sat = `${satelliteName} [${satelliteID}]`
    if (!satelliteName) return;
    if (!groupedBySatellites[sat]) {
      groupedBySatellites[sat] = [];
    }
    groupedBySatellites[sat].push({
      groundStationID: config.groundStationID,
      groundStationName: config.groundStationName
    });
  });
  return groupedBySatellites;
}

export const addSatNameToGsConfig = (
  groundStationConfigs: { satelliteID: any }[],
  satellites: any[]
) =>
  groundStationConfigs.map((gsConfig: any) => {
    const satellite = satellites.find((sat) => sat.id === gsConfig.satelliteID);
    const { configuration, ...rest } = gsConfig;
    return satellite
      ? { ...rest, satelliteName: satellite?.label || null }
      : gsConfig;
  });



// WORKAROUND UNTIL BE RETURNS VALIDATION IS VALID JSON
export const resetConfigFormData = (formData: any, validationErrRes: string) => {
  if (typeof formData !== "object" || formData === null || !Array.isArray(formData.groundStationsBySatellite)) return;
  if (typeof validationErrRes !== "string" || !/\d+/.test(validationErrRes)) return;
  const match = validationErrRes.match(/(\d+)/);
  if (!match) return;
  // IF SAT + GS providers are not configured, remove it from current formdata
  const satToRemove = match[0];
  formData.groundStationsBySatellite = formData.groundStationsBySatellite.filter(
    (i: any) => i && typeof i.satellite === "string" && !i.satellite.includes(`[${satToRemove}]`)
  );
  return formData
};