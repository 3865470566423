import React, { useState, memo, useEffect, useContext } from "react";
import { Flex } from "primitives";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Filter,
  ResetButton,
  CreateButton,
  CopyButton
} from "components";
import {
  Oa,
  OperationActivityFilterState,
  OperationDialogActionName,
  ContextActionsNames
} from "../models";
import styled from "styled-components";
import { CommonProps, COMMON, FLEX, FlexProps } from "styles/constants";
import { isEqual } from "lodash";
import { INITIAL_FILTERS } from "app/planningSystem/utils/constants";
import { filterOperations } from "app/planningSystem/utils/helpers";
import palette from "styles/palette";
import { OperationActivityPagination } from "app/planningSystem/containers";
import { PlanningSystemContext } from "app/planningSystem/context";
import { EditButton } from "components";
import { DeleteButton } from "components";

const Container = styled.div<FlexProps & CommonProps>`
  ${COMMON}
  ${FLEX}
`;

const NoResult = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-color: ${palette.palette.grey[5]};
`;

export const DrawerContent = memo(
  ({ oa, ...otherProps }: { oa: Oa[] } & CommonProps) => {
    const { dispatch } = useContext(PlanningSystemContext);
    const [filters, setFilters] =
      useState<OperationActivityFilterState>(INITIAL_FILTERS);
    const [filteredOperations, setFilterdOperations] = useState<Oa[]>(oa);

    useEffect(() => {
      setFilterdOperations(filterOperations(filters, oa) || []);
    }, [filters, oa]);

    const handleOnFilterChange = (
      filter: string,
      filterName: keyof OperationActivityFilterState
    ): void => {
      setFilters((prevState) => ({ ...prevState, [filterName]: filter }));
    };

    const handleOperationActitivity = (
      actionName: OperationDialogActionName,
      operation?: Oa
    ) => {
      const payload = {
        isOpen: true,
        setOpen: dispatch,
        actionName,
        ...((operation && { oa: operation }) || {})
      };
      dispatch({ type: ContextActionsNames.operationActivityDialog, payload });
    };

    return (
      <Container flex={1} p={2} {...otherProps} data-testid={"drawer-content"}>
        <Flex pr={2} mb={6} justifyContent={"flex-end"}>
          <CreateButton
            title={"Create new Operation Activity Request"}
            onClick={() =>
              handleOperationActitivity(OperationDialogActionName.create)
            }
          >
            New OA
          </CreateButton>
        </Flex>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Name
                <Flex data-testid={"filter-by-name"}>
                  <Filter
                    value={filters.name}
                    field={"name"}
                    onChange={handleOnFilterChange}
                  />
                  <ResetButton
                    ml={1}
                    size={6}
                    onClick={() => handleOnFilterChange("", "name")}
                    title={"Clear filter"}
                    disabled={!filters.name}
                  />
                </Flex>
              </TableCell>
              <TableCell>
                Type
                <Flex data-testid={"filter-by-oaType"}>
                  <Filter
                    value={filters.oaType}
                    field={"oaType"}
                    onChange={handleOnFilterChange}
                  />
                  <ResetButton
                    ml={1}
                    size={6}
                    onClick={() => handleOnFilterChange("", "oaType")}
                    title={"Clear filter"}
                    disabled={!filters.oaType}
                  />
                </Flex>
              </TableCell>
              <TableCell>
                Status
                <Flex data-testid={"filter-by-status"}>
                  <Filter
                    value={filters.status}
                    field={"status"}
                    onChange={handleOnFilterChange}
                  />
                  <ResetButton
                    ml={1}
                    size={6}
                    onClick={() => handleOnFilterChange("", "status")}
                    title={"Clear filter"}
                    disabled={!filters.status}
                  />
                </Flex>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOperations.length > 0 &&
              filteredOperations.map((op, i) => (
                <TableRow data-testid="operation-activity-row" key={i}>
                  <TableCell>{op.name}</TableCell>
                  <TableCell>{op.oaType}</TableCell>
                  <TableCell>{op.status}</TableCell>
                  <TableCell>
                    <Flex justifyContent={"center"}>
                      <EditButton
                        onClick={() =>
                          handleOperationActitivity(
                            OperationDialogActionName.edit,
                            op
                          )
                        }
                      />
                    </Flex>{" "}
                  </TableCell>
                  <TableCell>
                    <Flex justifyContent={"center"}>
                      <CopyButton
                        data-testid={"duplicate-oa"}
                        title={"Duplicate"}
                        onClick={() =>
                          handleOperationActitivity(
                            OperationDialogActionName.create,
                            op
                          )
                        }
                      />
                    </Flex>{" "}
                  </TableCell>
                  <TableCell>
                    <Flex justifyContent={"center"}>
                      <DeleteButton
                        onClick={() =>
                          handleOperationActitivity(
                            OperationDialogActionName.delete,
                            op
                          )
                        }
                      />
                    </Flex>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {filteredOperations.length === 0 && <NoResult>No result</NoResult>}
        <OperationActivityPagination />
      </Container>
    );
  },
  (
    { oa: prevOperations }: { oa: Oa[] } & CommonProps,
    { oa: nextOperations }: { oa: Oa[] } & CommonProps
  ) => {
    return isEqual(prevOperations, nextOperations);
  }
);
