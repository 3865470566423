import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import palette from "styles/palette";
import { AlertConfig, AlertConfigDetails, Colors } from "../models";
import { highlightKeywords } from "./AlertsEditor";

type AlertConditionsProps = {
  alertConfigs?: AlertConfig[];
};

type AlertTypeProps = {
  type?: string;
};

type AlertConditionProps = {
  condition: string;
};

export type AlertDetailsProps = {
  alertDetails?: AlertConfigDetails;
};

export const AlertConditions = ({ alertConfigs }: AlertConditionsProps) => {
  return (
    <>
      {Array.isArray(alertConfigs) &&
        alertConfigs.map((c: any, idx: number) => {
          return (
            <StyledWrapperDiv>
              {c?.name ? <><StyledAlertConfigName>{c.name}</StyledAlertConfigName> | </> : null}
              <StyledIndex type={c.severity}> {c.severity}:</StyledIndex>
              <AlertCondition condition={c.condition} key={idx} />
            </StyledWrapperDiv>
          );
        })}
    </>
  );
};

export const AlertType = ({ type }: AlertTypeProps) => {
  return <>{type?.replace("Alert", "")} </>;
};

type AlertLevel = {
  severity: string;
  lowerThreshold: number;
  upperThreshold: number;
};

function renderAlertLevel(
  c: AlertLevel,
  idx: number,
  parentIdx: null | number = null
) {
  return (
    <StyledDetails key={idx}>
      <StyledIndex type={c.severity}> {c.severity}:</StyledIndex>
      {parentIdx !== null && (
        <StyledIndexWrapper>
          [<StyledIndex>{parentIdx}</StyledIndex>]
        </StyledIndexWrapper>
      )}
      <StyledDetailsSpan>
        lowerThreshold:
        <StyledDetailsSpanValue>{c.lowerThreshold}</StyledDetailsSpanValue>
      </StyledDetailsSpan>
      |
      <StyledDetailsSpan>
        upperThreshold:
        <StyledDetailsSpanValue>{c.upperThreshold}</StyledDetailsSpanValue>
      </StyledDetailsSpan>
    </StyledDetails>
  );
}

export const AlertDetails = ({ alertDetails }: AlertDetailsProps) => {
  return (
    <StyledWrapper>
      {Array.isArray(alertDetails?.alertLevels) &&
        alertDetails?.alertLevels.map((c: AlertLevel, idx: number) =>
          renderAlertLevel(c, idx)
        )}
      {Array.isArray(alertDetails?.indexes) &&
        alertDetails?.indexes.map(
          (index: AlertConfigDetails, parentIdx: number) => (
            <StyledWrapperByIndex>
              {index.alertLevels?.map((c: AlertLevel, idx: number) =>
                renderAlertLevel(c, idx, parentIdx)
              )}
            </StyledWrapperByIndex>
          )
        )}
    </StyledWrapper>
  );
};

const AlertCondition = ({ condition }: AlertConditionProps) => {
  const highlightedTextRef = useRef(null);
  const highlighted = highlightKeywords(condition);

  useEffect(() => {
    if (highlightedTextRef && highlightedTextRef.current) {
      // @ts-ignore
      highlightedTextRef.current.innerHTML = highlightKeywords(condition);
    }
  }, [condition]);
  return <StyledDiv ref={highlightedTextRef}>{highlighted}</StyledDiv>;
};

const StyledWrapperDiv = styled.div`
  width: 100%;
  line-height: 20px;
  background-color: ${palette.palette.custom[1]};
  padding: 5px 5px;
  margin: 5px 0px;
  font-family: monospace;
`;

const StyledDiv = styled.span`
  width: 100%;
  color: white;
  font-size: 12px;
  background-color: ${palette.palette.custom[1]};
  border-width: 1px 1px 1px 1px;
  padding: 5px 5px;
  margin: 5px 0px;
  font-family: monospace;
`;

const StyledWrapper = styled.div`
  color: white;
  padding: 5px 5px;
  margin: 5px 0px;
`;

const StyledWrapperByIndex = styled.div`
  padding: 5px 0px;
`;

const StyledDetails = styled.div`
  width: 100%;
  color: white;
  background-color: ${palette.palette.custom[1]};
  padding: 5px 5px;
  margin: 5px 0px;
  font-family: monospace;
`;

const StyledDetailsSpan = styled.span`
  padding: 10px 5px;
  margin: 5px 0px;
  font-size: 12px;
`;

const StyledDetailsSpanValue = styled.span`
  color: ${palette.palette.green[1]};
`;

interface StyledIndexProps {
  type?: keyof typeof Colors | string;
}

const COLORS = {
  Warning: palette.palette.orange[1],
  Critical: palette.palette.red[1]
};

const StyledIndex = styled.span<StyledIndexProps>`
  font-size: 12px;
  font-weight: bold;
  color: ${({ type }) =>
    type ? COLORS[type as keyof typeof COLORS] : "inherit"};
`;

const StyledIndexWrapper = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

const StyledAlertConfigName = styled.span<StyledIndexProps>`
  font-size: 12px;
  font-style: italic;
`;