import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "components/table/Table";
import { Box } from "primitives";
import { SatelliteInstanceEdit } from "./SatelliteInstanceEdit";
import { SatelliteInstanceDelete } from "./SatelliteInstanceDelete";
import { SatelliteInstance } from "app/satellite/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";

interface InstanceListProps {
  satelliteInstances: SatelliteInstance[];
  satelliteDefinitions: SatelliteDefinition[];
  onChange: () => void;
}

interface InstanceListState {}

class InstanceList extends Component<InstanceListProps, InstanceListState> {
  render() {
    const { satelliteInstances, satelliteDefinitions } = this.props;

    if (satelliteInstances && satelliteInstances.length > 0) {
      return (
        <Table mt={2} backgroundColor="transparent" data-testid="InstanceList">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Satellite definition</TableCell>
              <TableCell>TLE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {satelliteInstances
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.label}</TableCell>
                    <TableCell>
                      {row.satelliteDefinitionSummary.satelliteDefinitionId &&
                        row.satelliteDefinitionSummary.name}
                    </TableCell>
                    <TableCell>
                      {typeof row?.tle === "string"
                        ? row.tle.split("\n").map((line, i) => (
                            <Box key={i} fontSize={3}>
                              {line}
                            </Box>
                          ))
                        : ""}
                    </TableCell>

                    <TableCell>
                      <SatelliteInstanceEdit
                        record={row}
                        satelliteDefinitions={satelliteDefinitions}
                        onChange={() => this.props.onChange()}
                      />
                      <SatelliteInstanceDelete
                        record={row}
                        onChange={() => this.props.onChange()}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      );
    } else {
      return null;
    }
  }
}

export const SatelliteInstanceList = InstanceList;
