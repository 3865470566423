import React, { useEffect, useState } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import {
  DataTypes,
  EncryptionKey,
  Channels,
  EncryptionKeyOrIV
} from "../models";
import { useSelector } from "react-redux";
import { Actions } from "./Actions";

interface TableVisualizationProps {
  dataType: number;
}

const tabLabels = Object.values(DataTypes);

export const TableVisualization = ({ dataType }: TableVisualizationProps) => {
  const { encryptionKeys, initializationVectors } = useSelector<any, any>(
    (state: any) => state.keyManager
  );
  const [tableData, setTableData] = useState<EncryptionKeyOrIV[]>([]);

  useEffect(() => {
    setTableData(getTabledata(dataType));
  }, [dataType, encryptionKeys, initializationVectors]);

  const getTabledata = (dataType: number): EncryptionKeyOrIV[] => {
    switch (tabLabels[dataType]) {
      case DataTypes.INITIALIZATION_VECTORS:
        return initializationVectors;
      case DataTypes.KEY_OBC:
        return encryptionKeys.filter(
          (k: EncryptionKey) => k.channel === Channels.OBC
        );
      case DataTypes.KEY_PMU:
        return encryptionKeys.filter(
          (k: EncryptionKey) => k.channel === Channels.PMU
        );

      default:
        return [];
    }
  };
  const isIVS = tabLabels[dataType] === DataTypes.INITIALIZATION_VECTORS;
  return (
    <Table marginTop={20} maxWidth={"100%"}>
      <TableHead>
        <TableRow bg="fill.0">
          <TableCell width="auto">Satellite Instance ID</TableCell>
          {isIVS ? (
            <TableCell width="auto">Satellite IV ID</TableCell>
          ) : (
            <TableCell width="auto">Satellite Key ID</TableCell>
          )}
          <TableCell width="auto">State</TableCell>
          {!isIVS && <TableCell width="auto">Channel</TableCell>}
          <TableCell width="auto">Activated at</TableCell>
          <TableCell width="auto">Expires at</TableCell>
          <TableCell width="10px">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData.map((td, idx) => (
          <TableRow key={idx} data-testid={"key-tr"}>
            <TableCell>{td.satellite_instance_id}</TableCell>
            {isIVS ? (
              <TableCell>{td.satellite_iv_id}</TableCell>
            ) : (
              <TableCell>{td.satellite_key_id}</TableCell>
            )}
            <TableCell>{td.state}</TableCell>
            {isIVS ? null : <TableCell>{td?.channel}</TableCell>}
            <TableCell>{td.activated_at || "n/a"}</TableCell>
            <TableCell>{td.expires_at || "n/a"}</TableCell>
            <TableCell>
              <Actions isIVS={isIVS} keyOrIv={td} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
