import {
  DataSource,
  DataSourceIndexTypes,
  DataSourceReading,
  Reading
} from "app/telemetry/models/datasources";
import { orderBy } from "lodash";
import { SortingOptions } from "./models";
import { UnitConverter } from "./unitConverter";
import {
  parseOptions,
  findObjectByProperty,
  scaleDataSourceReading
} from "./utils";
import { getDateFromTimeController } from "app/shared/timeController/utils";
import { AlignedData } from "uplot";
import {
  TelemetryData,
  TimeStamps,
  TimeSeriesData,
  AlertInfoByIndex,
  TimeSeriesDataWithAlertInfo,
  HandleMinMaxResult,
  AlertInfoByTimestamp,
  AlertInfoObj
} from "./models/graphData";

export class GraphDataModel {
  static INVALID_FLAGS = "4294967295,4294967296";
  static INVALID_FLAG_ALPHA = 20;

  static MARGIN = {
    top: 100,
    left: 80,
    bottom: 30,
    right: 40
  };

  data: any;
  configOptions: any;
  width: any;
  height: any;
  axesRef: any;
  unitConverter: any;
  tooltip: any;

  constructor(
    data: any,
    configOptions: any,
    height: number,
    width: number,
    axesRef: React.RefObject<SVGElement>,
    unitConverter: UnitConverter
  ) {
    this.axesRef = axesRef;
    this.configOptions = parseOptions(configOptions);
    this.height = height;
    this.width = width;
    this.data = data;
    this.unitConverter = unitConverter;
    // Based on configOptions, allow users to remove/clean specific values from the dataset,
    // can we pass a callback as config string as eval it on the fly?
    this.data = scaleDataSourceReading(
      data,
      this.scaleValue,
      this.configOptions.widgetName
    );
  }

  getLineChartData = () => {
    /*
    This step further transforms graph data from redux store based on each widget configuration. 
    Since widget configurations are not available at telemetry reducer, this step ensures
    additional modifications are applied to data before line chart is rendered
    */
    const tmDataFromReduxStore: TelemetryData[] = this.data ?? [];

    const timestamps: TimeStamps =
      this.getTimestampsByRef(tmDataFromReduxStore);

    const {
      labels,
      seriesData
    }: { labels: string[]; seriesData: TimeSeriesData[] } =
      this.generateTimeSeriesData(tmDataFromReduxStore);

    // without timestamps(first) & alerts(last series)
    const tmReadingsByIndexes: TimeSeriesData[] = seriesData.slice(0, -1);
    const alertInfoAggregatedForAllDatasources: TimeSeriesData[] =
      seriesData.slice(-1);
    const alertsByTimestamps = [
      ...alertInfoAggregatedForAllDatasources[0].readings
    ];

    const { withMinMax: valueSeries, invalidValues } =
      this.handleMinMax(tmReadingsByIndexes);

    /*
     [0] first series for x-axis needs to be unix timestamps,
     [n...] rest of the actual tm value series for each datasource index
     [-1] last series is hidden alert-info to show alert data on clicked
     */
    const timeSeriesData: AlignedData = [
      timestamps,
      ...valueSeries,
      alertsByTimestamps
    ];

    return {
      labels: labels,
      data: timeSeriesData || [[], []],
      invalidValues
    };
  };

  getTimestampsByRef = (tmDataFromReduxStore: TelemetryData[]): TimeStamps => {
    // each graph can choose which timestamp format to use for x-axis
    const timeReferenceSelectedInConfig =
      this.configOptions.timeReference.reference;
    const allTimeStamps = tmDataFromReduxStore[0]?.readings[0] || [];
    const timestamps = allTimeStamps[timeReferenceSelectedInConfig] ?? [];
    return timestamps;
  };

  generateTimeSeriesData = (
    tmDataFromReduxStore: TelemetryData[]
  ): {
    labels: string[];
    seriesData: TimeSeriesData[];
  } => {
    const seriesData: TimeSeriesData[] = tmDataFromReduxStore.reduce(
      (acc: TimeSeriesData[], tmData: TelemetryData) => {
        const selectedIndexesForDatasource =
          this.getSelectedIndexesForDatasource(tmData);

        const series: TimeSeriesData[] = [];
        const alertInfoByDatasourceIndex: AlertInfoByIndex = {};
        const readings = tmData.readings.slice(1, -1);

        readings.forEach((reading: any, datasourceIndex: number) => {
          if (selectedIndexesForDatasource.includes(datasourceIndex)) {
            series.push({
              label: `${tmData.dataSource.name}[${datasourceIndex}]`,
              reading: reading
            });
            this.handleAlerts(
              tmData,
              datasourceIndex,
              series,
              alertInfoByDatasourceIndex
            );
          }
        });

        return acc.concat(series);
      },
      []
    );

    const alertInfo: TimeSeriesDataWithAlertInfo =
      this.aggregateAlertInfo(seriesData);

    const tmValueSeries = seriesData.filter(
      (i: TimeSeriesData) => !this.isAlertInfoSeries(i)
    );

    // place alert-info at the end to access it in click-listener
    // const reorderedSeriesData = [...tmValueSeries, alertInfo];
    const reorderedSeriesData: TimeSeriesData[] = [...tmValueSeries, alertInfo];
    // const labels = reorderedSeriesData.map((r: any) => r?.label) || [];
    const labels: string[] =
      reorderedSeriesData.map((r: TimeSeriesData) => r?.label) || [];

    return { labels, seriesData: reorderedSeriesData };
  };

  getTimeReference = (): string => {
    const REFS: Record<string, string> = {
      groundUtc: "receiveTimestamp",
      satelliteUtc: "sendTimestamp",
      satelliteBootcountObt: "onboardTimeMillis"
    };
    const timeReference: string =
      this.configOptions.timeReference.reference || "";
    return REFS[timeReference];
  };

  aggregateAlertInfo = (
    seriesData: TimeSeriesData[]
  ): TimeSeriesDataWithAlertInfo => {
    const timeReference = this.getTimeReference();
    function alignAlertInfoByTimestamp(alertInfoObj: AlertInfoObj) {
      const seriesLength = seriesData[0]?.reading?.length || 0;
      const alertKV: AlertInfoByTimestamp[] = Array(seriesLength).fill(null);

      Object.entries(alertInfoObj).forEach(([readingKey, readingValues]) => {
        Object.entries(readingValues).forEach(([index, dataList]) => {
          if (dataList) {
            dataList.forEach((data: any, i: number) => {
              if (data) {
                const key = `[${timeReference}][${data[timeReference]}]`;
                alertKV[i] = alertKV[i] || {};
                alertKV[i][key] = alertKV[i][key] || [];
                alertKV[i][key].push({
                  ds: `${readingKey}[${index}]`,
                  ...data
                });
              }
            });
          }
        });
      });
      return alertKV as AlertInfoByTimestamp[];
    }

    const alertInfo: AlertInfoObj = seriesData
      .filter((i: any) => this.isAlertInfoSeries(i))
      .reduce((acc: any, curr: any) => {
        acc[curr.label] = curr.reading;
        return acc;
      }, {});

    const alignedAlertInfoByTimestamp = alignAlertInfoByTimestamp(
      alertInfo
    ) as any;
    return { label: "alert-info", readings: alignedAlertInfoByTimestamp };
  };

  isAlertInfoSeries = (i: TimeSeriesData): Boolean => {
    return i.label?.startsWith("alert-info") || false;
  };

  handleAlerts = (
    tmData: TelemetryData,
    datasourceIndex: number,
    series: TimeSeriesData[],
    alertInfoByIndex: AlertInfoByIndex
  ) => {
    if (!this.configOptions?.alerts?.showAlerts) return;

    const filterByType = (
      alerts: any,
      returnValue: boolean
    ): (number | null)[] =>
      alerts?.map((a: any) =>
        a && a.type !== "NormalValue" ? (returnValue ? a.value : a) : null
      ) || [];

    const lastReading = tmData.readings?.slice(-1)[0];
    const alertsSeries: (Reading | null)[][] = Array.isArray(lastReading)
      ? (lastReading as (Reading | null)[][])
      : [];

    if (alertsSeries.length) {
      const alertSeries = alertsSeries[0];

      if (alertSeries && Array.isArray(alertSeries[datasourceIndex])) {
        const label = `alerts-${tmData.dataSource.name}[${datasourceIndex}]`;
        const reading = filterByType(alertSeries[datasourceIndex], true);
        series.push({ label, reading });

        // on a give timestamp (x), we might have multiple alerts for multiple ds indexes
        alertInfoByIndex[datasourceIndex] = filterByType(
          alertSeries[datasourceIndex],
          false
        );
      }
    }
    // alert-info series is hidden yet accessible on the graph, clicking a point shows data from this series
    series.push({
      label: `alert-info-[${tmData.dataSource.name}]`,
      reading: alertInfoByIndex
    });
  };

  handleMinMax = (
    tmReadingsByIndexes: TimeSeriesData[]
  ): HandleMinMaxResult => {
    // if min is 0 and max is auto, use auto range as default
    // if min, max is "auto", pass min,max as null to enable auto range creation
    const { min: _min, max: _max } = this.configOptions.scale;
    // this could be moved into a method
    const scale = this.configOptions?.scale;
    const min =
      scale && scale.min && isNaN(scale.min) ? null : Number(scale.min);
    const max =
      scale && scale.max && isNaN(scale.max) ? null : Number(scale.max);
    const invalidValues: any = {};
    const hasMinMax = min !== null && max !== null;

    const withMinMaxRecapped = (tmReadingsByIndexes || []).map((r: any) => {
      // if (r?.label === "alert-info" || r?.label?.includes("alert-info")) {
      //   return { [r?.label]: r.reading };
      // }
      if (hasMinMax) {
        return r.reading.map((rr: any, idx: number) => {
          if (isNaN(rr) || rr === null || typeof rr !== "number") {
            return rr;
          }
          if (rr && rr < min) {
            // attach index of invalid value and remap it to current min
            // handle float values manually
            const isFloat = min.toString().includes(".");
            const cappedValue = isFloat
              ? `${min}000${idx}`
              : `${min}.000${idx}`;
            invalidValues[idx] = rr;
            const recapped = Number(cappedValue);
            console.log(`${rr} < min:${min}, recapping value to ${recapped}`);
            return recapped;
          }
          if (rr && rr > max) {
            // attach index of invalid value and remap it to current max
            const isFloat = max.toString().includes(".");
            const cappedValue = isFloat
              ? `${max}000${idx}`
              : `${max}.000${idx}`;
            invalidValues[idx] = rr;
            const recapped = Number(cappedValue);
            console.log(`${rr} > max:${max}, recapping value to ${recapped}`);
            return recapped;
          }
          return rr;
        });
      }
      return r.reading;
    });

    return { withMinMax: withMinMaxRecapped, invalidValues };
  };

  getSelectedIndexesForDatasource = (datasource: any) => {
    const config = this.configOptions.dataSources.find(
      (d: any) => d.id === datasource.dataSource.id
    );
    // if datasource has multiple indexes, return those, otherwise [0] is default for datasources with single values
    return config && config.indexes
      ? config.indexes.map((i: any) => i.index)
      : [0];
  };

  getLineChartConfig = () => {
    const options = this.configOptions;
    const { quickRange } = options.timeController;
    const { from, to } = quickRange
      ? getDateFromTimeController(options.timeController)
      : options.timeController;
    const target = this.axesRef;
    const lineArea = options.plot.showArea ?? false;
    const showPoints = options.plot.showPoints;
    const plotType = options.plot.type;
    const thickness = options.plot.thickness ?? 1;

    const w = this.getWidth();
    const h = this.getHeight();
    const showLegend = options.legend.visibilityMode === "visible";
    const showLegendAsToolTip =
      options.legend.visibilityMode === "onHoverVisible";
    // calculate legend margin based on number of indexes?
    const legendMargin = options.legend.margin ?? 30;

    // if min is 0 and max is auto, use auto range as default
    // if min, max is "auto", pass min,max as null to enable auto range creation
    const { min: _min, max: _max } = options.scale;
    // this could be moved into a method
    const scale = options?.scale;
    const min = scale && scale.min && isNaN(scale.min) ? null : scale.min;
    const max = scale && scale.max && isNaN(scale.max) ? null : scale.max;

    // threshold limits
    const limits = options.limits || {};
    const unit = this.getUnit();

    return {
      limits,
      min,
      max,
      lineArea,
      showPoints,
      plotType,
      thickness,
      options,
      from,
      to,
      target,
      w,
      h,
      showLegend,
      showLegendAsToolTip,
      legendMargin,
      unit
    };
  };

  getUnit = () => {
    const options = this.configOptions;
    const scale = options.scale || {};
    const showUnit = scale.showUnit ?? false;
    const _unit =
      scale && scale?.customUnit ? scale.customUnit : this.getDefaultUnit();
    const unit = showUnit ? _unit : "";
    return unit;
  };

  getData = () => {
    return this.data;
  };

  getXOptions = () => {
    // X axis options
    const xAxisOptions = this.configOptions.axes?.x;
    const valueMapping = this.configOptions.valueMapping;
    const showXAxis = xAxisOptions?.enabled ?? true;
    const showGrid = xAxisOptions?.grid?.enabled ?? false;
    const gridColor = xAxisOptions?.grid?.strokeColor ?? "grey";
    const gridStrokeOpacity = xAxisOptions?.grid?.strokeOpacity ?? 0.08;

    // scale X options
    const padding = this.configOptions.axes?.x?.padding ?? 0.5;
    const sortedData = this.sortData();

    const boundedHeight = this.getBoundedHeight();
    const boundedWidth = this.getBoundedWidth();
    return {
      valueMapping,
      showGrid,
      showXAxis,
      gridColor,
      gridStrokeOpacity,
      padding,
      sortedData,
      boundedHeight,
      boundedWidth
    };
  };

  getYOptions = () => {
    // X axis options
    const defaultUnit = this.getDefaultUnit();
    const yAxisOptions = this.configOptions.axes?.y;
    const scale = this.configOptions.scale;
    const showUnit = scale.showUnit ?? false;
    const shouldScale = scale.enableScaling.enableScaling;
    const unit = shouldScale ? scale?.enableScaling.scaledUnit : defaultUnit;

    const customOrDefault = scale?.customUnit
      ? scale.customUnit
      : this.getDefaultUnit() ?? "";
    const unitSymbol =
      shouldScale && unit
        ? this.unitConverter.getSymbol(unit)
        : customOrDefault;

    const showYAxis = yAxisOptions?.enabled ?? true;
    const showGrid = yAxisOptions?.gridEnabled ?? true;
    const gridColor = yAxisOptions?.gridStrokeColor ?? "grey";
    const gridStrokeOpacity = yAxisOptions?.gridStrokeOpacity ?? 0.01;
    const paddingTop = yAxisOptions?.paddingTop ?? 0;
    const noOfTicks = yAxisOptions?.noOfTicks ?? 4;

    // scale X options
    const _yAxisOptions = this.configOptions.scale;
    const enableMaxValidValue = this.configOptions.scale.enableMaxValid ?? true;
    const { maxValidValue } = this.getDataSourceValueTypes(this.getData());

    const isNil = (obj: any, min: string | number) =>
      obj[min] === "auto" || obj[min] === "" || obj[min] === undefined;

    const datasourceValues = this.getData().map((ds: DataSourceReading) =>
      this.getDataSourceValue(ds)
    );

    const min = isNil(_yAxisOptions, "min")
      ? Math.min(...datasourceValues)
      : parseInt(_yAxisOptions.min);

    const max = enableMaxValidValue
      ? maxValidValue
      : isNil(_yAxisOptions, "max")
      ? Math.max(...datasourceValues)
      : Number(_yAxisOptions.max);

    //Note: This offset is used to create a max value a bit higher than max valid values to allow invalid flags to appear but visually distinct from valid values. It is a workaround to handle invalid values without removing them
    const OFFSET = _yAxisOptions?.offset ? _yAxisOptions?.offset / 1000 : 0.001;

    const boundedHeight = this.getBoundedHeight();
    const boundedWidth = this.getBoundedWidth();
    return {
      OFFSET,
      min,
      max,
      gridColor,
      gridStrokeOpacity,
      noOfTicks,
      showUnit,
      unitSymbol,
      showYAxis,
      paddingTop,
      showGrid,
      boundedHeight,
      boundedWidth
    };
  };

  getBarOptions = () => {
    // bar coordinates config
    const yAxisOptions = this.configOptions.scale;
    const barWidth = this.configOptions.axes?.x?.barWidth ?? 5;
    const { maxValidValue } = this.getDataSourceValueTypes(this.data);

    // if current value is one of the invalid flags, assign y coordinate of maxValidValue + some offset

    // Note: Offset calculates the gap between max valid value and invalid value allowing users to see visual separation of values to ignore
    const _offset = yAxisOptions?.offset ? yAxisOptions.offset : 100;
    const OFFSET = (maxValidValue * _offset) / 500;

    // bar color config
    const valueMapping = this.configOptions.valueMapping;
    const invalidValueOpacity = this.configOptions.scale?.invalidValueOpacity;
    const defaultBarColor = this.configOptions.axes.x.defaultBarColor;

    const { validDataSources } = this.getDataSourceValueTypes(this.data);

    const boundedHeight = this.getBoundedHeight();

    return {
      barWidth,
      OFFSET,
      maxValidValue,
      defaultBarColor,
      valueMapping,
      invalidValueOpacity,
      validDataSources,
      boundedHeight
    };
  };

  getGaugeOptions = () => {
    const toRadian = Math.PI / 180;
    const options = this.configOptions;
    // if datasource has multiple indexes, pick the index otherwise use 0 as default
    const indexes = options.dataSources[0].indexes;
    const selectedIndex = indexes ? indexes[0]?.index : 0;
    const value = this.data[0]?.readings[0]?.value[selectedIndex].value;
    const dsLabel = this.data[0]?.dataSource?.name ?? "No DS";
    const id = this.data[0]?.dataSource.id;
    const color = options.color ?? "#784c65";
    const labelColor = options.styles?.labelColor ?? "#7ab9db";
    const labelFontSize = options?.styles.labelFontSize ?? 10;
    const labelTopMargin = options?.styles.labelTopMargin ?? 0;
    const valueFontSize = options?.styles.valueFontSize ?? 12;
    const valueColor = options?.styles.valueColor ?? "#7ab9db";
    const valuePathColor = options?.styles.valuePathColor ?? "#384c65";

    const thresholds = options.thresholds ?? [{ min: 0, max: 0 }];
    const tmin = thresholds.reduce(
      //@ts-ignore
      (min, { min: curMin }) => Math.min(min, curMin),
      thresholds[0].min
    );
    const tmax = thresholds.reduce(
      //@ts-ignore
      (max, { max: curMax }) => Math.max(max, curMax),
      thresholds[0].max
    );

    const scale = options.scale;
    const min = scale && scale.min && !isNaN(scale.min) ? scale.min : tmin;
    const max = scale && scale.max && !isNaN(scale.max) ? scale.max : tmax;

    const displayName =
      options.displayName?.length > 0
        ? options.displayName
        : `${dsLabel}[${selectedIndex}]`;
    const decimalPlace = options.floatDigits ?? 2;
    const label = options.label ?? "";
    const width = this.getWidth();
    const height = this.getHeight();
    const radius = Math.min(width, height) / 2;
    const outer = radius - radius * 0.5;
    const inner = radius - radius * 0.15;
    const innerArcCircle = radius - radius * 0.1;
    const minAngle = options.minAngle ?? -135;
    const maxAngle = options.maxAngle ?? 135;
    const startAngle = minAngle * toRadian;
    const endAngle = maxAngle * toRadian;
    const transform = `translate(${width / 2},${height / 2})`;
    const unit = this.getUnit();
    const valueToColor = (value: any) => {
      if (isNaN(value)) {
        return color;
      }
      const threshold = thresholds.find(
        //@ts-ignore
        (t) => value >= t.min && value <= t.max
      );
      return threshold?.color ?? value;
    };

    // @ts-ignore
    const valueToText = (value) => {
      if (isNaN(value)) {
        return value;
      }
      // handle scientific notation numbers separately
      if (value.toString().includes("e")) {
        return `${Number(value).toPrecision(decimalPlace)} ${unit}`;
      }
      return `${Number(value).toFixed(decimalPlace)} ${unit}`;
    };

    return {
      extent: [min, max],
      color,
      labelColor,
      labelFontSize,
      labelTopMargin,
      valueFontSize,
      valueColor,
      valuePathColor,
      value,
      thresholds,
      minAngle,
      maxAngle,
      label,
      displayName,
      width,
      height,
      toRadian,
      radius,
      outer,
      inner,
      innerArcCircle,
      startAngle,
      endAngle,
      transform,
      valueToColor,
      valueToText,
      id
    };
  };

  getDimensions = () => {
    const margins = this.getMargins();
    const height = this.getHeight();
    const width = this.getWidth();

    return {
      width,
      height,
      margins
    };
  };

  getMargins = () => {
    const left = this.configOptions.margin?.left ?? GraphDataModel.MARGIN.left;
    const top = this.configOptions.margin?.top ?? GraphDataModel.MARGIN.top;
    return { left, top };
  };

  getBoundedWidth = () => {
    if (this.configOptions.margin) {
      return (
        this.width -
        this.configOptions.margin.right -
        this.configOptions.margin.left
      );
    } else {
      return (
        this.width - GraphDataModel.MARGIN.right - GraphDataModel.MARGIN.left
      );
    }
  };

  getWidth = () => this.width;

  getHeight = () => this.height;

  getBoundedHeight = () => {
    if (this.configOptions.margin) {
      return (
        this.height -
        this.configOptions.margin.top -
        this.configOptions.margin.bottom
      );
    } else {
      return (
        this.height - GraphDataModel.MARGIN.top - GraphDataModel.MARGIN.bottom
      );
    }
  };

  sortData = () => {
    const valueMapping = this.configOptions?.valueMapping;

    const withLabels = this.data?.map((ds: DataSourceReading) => {
      const updatedLabelFromConfig = findObjectByProperty(
        valueMapping,
        ds.dataSource.id,
        "id"
      )?.label;
      const labelAssignedDuringWidgetCreation =
        this.configOptions.dataSources.find(
          (d: any) => d.id === ds.dataSource.id
        )?.label;

      return {
        ...ds,
        dataSource: {
          ...ds.dataSource,
          label: updatedLabelFromConfig ?? labelAssignedDuringWidgetCreation
        }
      };
    });

    const enableSort = this.configOptions?.axes?.x?.enableSort;

    const sortByProp =
      (this.configOptions?.axes?.x?.sortBy as DataSourceIndexTypes) ?? "label";

    const sortOrder =
      this.configOptions?.axes?.x?.sortOrder ?? SortingOptions.DESC;

    const orderByDefault = this.data?.sort(
      (a: DataSourceReading, b: DataSourceReading) => {
        const aIndex = this.configOptions.dataSources.findIndex(
          (obj: { id: number }) => obj.id === a.dataSource.id
        );
        const bIndex = this.configOptions.dataSources.findIndex(
          (obj: { id: any }) => obj.id === b.dataSource.id
        );
        return aIndex - bIndex;
      }
    );

    //@ts-ignore
    const sortedData = enableSort
      ? orderBy(
          withLabels,
          // @ts-ignore
          (ds: DataSourceReading) => ds.dataSource[sortByProp?.toLowerCase()],
          [sortOrder?.toLowerCase()]
        )
      : orderByDefault;

    return sortedData;
  };

  getDefaultUnit = () => {
    const defaultUnit = [
      ...new Set(
        this.data
          ?.map((i: any) => {
            if (i.dataSource.units) {
              return i.dataSource.units.unit;
            }
          })
          .flat()
      )
    ][0];
    return defaultUnit ? defaultUnit : "";
  };

  applyTransform = (dsValue: any) => {
    const scaleOptions = this.configOptions.scale;
    const transformFun = scaleOptions?.transform;
    if (!transformFun || isNaN(dsValue)) return dsValue;
    const regex = /([+\-*/])\s*(\d+\.?\d*)/;
    // add additional validations here
    const matches = transformFun && transformFun.match(regex);
    const operator = Array.isArray(matches) ? matches[1] : null;
    const operand = Array.isArray(matches) ? matches[2] : null;

    const transform = (dsValue: number, operand: number, operator: string) => {
      switch (operator) {
        case "*":
          return dsValue * operand;
          break;
        case "/":
          return dsValue / operand;
          break;
        case "-":
          return dsValue - operand;
          break;
        case "+":
          return dsValue + operand;
          break;
        default:
          throw Error("error parsing transform function");
          break;
      }
    };
    if (dsValue && operand && operator) {
      return transform(dsValue, operand, operator);
    }
    return dsValue;
  };

  scaleValue = (value: any) => {
    const scaleOptions = this.configOptions.scale;
    const shouldScale = scaleOptions?.enableScaling?.enableScaling;
    const scaledUnit = shouldScale
      ? scaleOptions?.enableScaling?.scaledUnit
      : this.getDefaultUnit();

    const scaledValue = this.unitConverter.convert(
      this.getDefaultUnit(),
      value,
      scaledUnit
    );

    const transformedValue = this.applyTransform(scaledValue);
    return transformedValue;
  };

  getDataSourceValue = (ds: DataSourceReading) => {
    const valueMapping = this.configOptions?.valueMapping;
    if (!ds) return 0;
    const id = ds.dataSource.id;
    if (valueMapping && Object.keys(valueMapping).length) {
      //Note: Value mappings are configured after widget is created.Until indexes are explicitly modified, use 0 as default index for each datasource
      const selectedIndex = findObjectByProperty(valueMapping, id, "id")?.index;
      const dsIndex = selectedIndex ? parseInt(selectedIndex) : 0;
      const reading = ds.readings[0]?.value[dsIndex]?.value;
      return reading;
    }
    const reading = ds.readings[0]?.value[0]?.value;
    return reading;
  };

  getDataSourceValueTypes = (data: DataSourceReading[]) => {
    if (!Array.isArray(data))
      return {
        validDataSources: [],
        invalidDataSources: [],
        maxValidValue: 0
      };
    const invalidDataSourcesValues =
      this.configOptions.scale.invalidValues ?? GraphDataModel.INVALID_FLAGS;
    const _invalidNums = invalidDataSourcesValues
      ?.split(",")
      .map((i: any) => parseInt(i));

    const enableScaling = this.configOptions.scale?.enableScaling;

    const invalidNums = enableScaling
      ? _invalidNums.map(this.scaleValue)
      : _invalidNums;

    const validDataSources = data.filter((ds: DataSourceReading) => {
      return !invalidNums?.includes(this.getDataSourceValue(ds));
    });
    const invalidDataSources = data.filter((ds: DataSourceReading) => {
      return invalidNums?.includes(this.getDataSourceValue(ds));
    });

    const maxValidValue = Math.max(
      ...(validDataSources.map((ds: DataSourceReading) =>
        this.getDataSourceValue(ds)
      ) as any)
    );
    return { validDataSources, invalidDataSources, maxValidValue };
  };

  formatLabelAxisX(datasourceName: any, valueMappingList: any) {
    const mapping = findObjectByProperty(
      valueMappingList,
      datasourceName,
      "datasource"
    );
    if (mapping?.label) {
      return mapping.label;
    }
    return datasourceName;
  }

  makeLabel(ds: any) {
    const { axes } = this.configOptions;
    const showIndexOnLabel = axes.x?.showIndex ?? false;
    return showIndexOnLabel ? `${ds.label}[${ds.index}]` : ds.label;
  }
  getDataSourceLabel = (datasource: { dataSource: DataSource }) => {
    const datasourceId = datasource.dataSource.id;
    const { valueMapping, dataSources } = this.configOptions;
    const updatedLabel = findObjectByProperty(valueMapping, datasourceId, "id");
    if (updatedLabel) {
      return this.makeLabel(updatedLabel);
    }
    const defaultLabel = findObjectByProperty(dataSources, datasourceId, "id");
    if (defaultLabel) {
      return this.makeLabel(defaultLabel);
    }
    return datasource.dataSource.name;
  };
}
