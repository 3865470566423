import {
  SatelliteDefinition,
  SatelliteDefinitionSystem
} from "app/satelliteDefinition/models";
import { searchDataSourceById } from "app/shared/tree/utils";
import {
  Component,
  Dashboard,
  DashboardDataSourceDefinition,
  DataSource,
  ExtendedDashboard,
  ExtendedDataSource
} from "../models";

export const isNotEmptyObject = (obj: Object) =>
  typeof obj === "object" && obj !== null && Object.keys(obj).length > 0;

type Key = "id" | "datasourceId";

type ExportOptions = {
  dashboardId: string;
  exportRawObject: boolean;
};

type DashboardList = {
  dashboards: ExtendedDashboard[];
};
export const remapDatasourceIds = (
  ds: ExtendedDataSource,
  systems: SatelliteDefinitionSystem[],
  key: Key
) => {
  if (ds[key]) {
    const datasourceId = !isNaN(ds[key]) ? Number(ds[key]) : ds[key];
    const datasource = searchDataSourceById(systems, datasourceId);
    if (!datasource) {
      console.warn("datasource not found", ds, ds[key]);
      return null;
    }
    return {
      ...ds,
      [key]: !isNaN(ds[key] as any)
        ? datasource.fullyQualifiedName
        : datasource.id
    };
  }
  return ds;
};

export const processImportPayload = (
  dashboardList: DashboardList,
  satDef: SatelliteDefinition
) => {
  const processedPayload = dashboardList.dashboards.map(
    (d: ExtendedDashboard) => {
      const isRawPayload = d.applicableSatDefinition;
      if (isRawPayload) {
        delete d.ownerId;
        delete d.id;
        delete d.applicableSatDefinition;
        return {
          ...d,
          satDefinitionId: satDef.id
        };
      }
      delete d.sat_name;
      return {
        ...d,
        satDefinitionId: satDef.id,
        components: transformDashboardComponents(d.components, satDef.systems)
      };
    }
  );
  return processedPayload;
};

export const sanitizeDashboard = (
  dashboard: ExtendedDashboard | Dashboard,
  satName: string
) => {
  delete dashboard.ownerId;
  delete dashboard.id;
  delete dashboard.applicableSatDefinition;
  return {
    sat_name: satName,
    ...dashboard
  };
};

export const transformDashboardPayload = (
  dashboards: ExtendedDashboard[],
  exportOptions: ExportOptions,
  satDefinition: SatelliteDefinition
) => {
  const { systems, id, name } = satDefinition;
  const doFilter = (dashboard: ExtendedDashboard) =>
    exportOptions.dashboardId === "ALL"
      ? dashboard
      : dashboard.id === exportOptions.dashboardId;

  const dashboardsWithRemappedIDs = dashboards
    .filter(doFilter)
    .map((dashboard: ExtendedDashboard) => {
      if (exportOptions.exportRawObject) {
        return dashboard;
      }
      return {
        ...sanitizeDashboard(dashboard, name),
        components: transformDashboardComponents(dashboard.components, systems)
      };
    });

  return { dashboards: dashboardsWithRemappedIDs };
};

function transformDashboardComponents(
  components: Component[],
  systems: SatelliteDefinitionSystem[]
) {
  return components.map((component: Component) => {
    let configDataSources: any = [];
    let componentDataSources: any = [];
    let valueMapping = {};
    const configDatasources = component.configOptions?.dataSources;
    const mapping = component.configOptions?.valueMapping;
    const dataSources = component?.dataSources;

    if (configDatasources && Array.isArray(configDatasources)) {
      configDataSources = configDatasources
        .map((ds) => remapDatasourceIds(ds, systems, "id"))
        .filter((ds) => ds?.id);
    }

    if (mapping && isNotEmptyObject(mapping)) {
      const _mapping = Object.values(mapping);
      const hasDatasources = _mapping.some((o: any) => "id" in o);

      valueMapping = hasDatasources
        ? _mapping
            .map((ds) =>
              remapDatasourceIds(ds as ExtendedDataSource, systems, "id")
            )
            .filter((ds) => ds?.id)
            .reduce((acc, obj, index) => ({ ...acc, [index]: obj }), {})
        : _mapping;
    }

    if (dataSources && Array.isArray(dataSources)) {
      componentDataSources = dataSources
        .map((ds) =>
          remapDatasourceIds(ds as ExtendedDataSource, systems, "datasourceId")
        )
        .filter((ds) => ds?.datasourceId);
    }

    const updatedComponent = {
      ...component,
      configOptions: {
        ...component.configOptions,
        dataSources: configDataSources,
        valueMapping: valueMapping
      },
      dataSources: componentDataSources
    };
    return updatedComponent;
  });
}

export const isValidJSON = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e: any) {
    console.error("Imported file is not a valid json string:", e);
  }
  return false;
};

export const convertRGB2Hex = (rgba: string) => {
  const match = rgba?.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]*)?\)$/);
  if (!match) return rgba;
  const [r, g, b] = match.slice(1, 4).map(Number);
  return `#${[r, g, b].map((c) => c.toString(16).padStart(2, "0")).join("")}`;
};

export const convertStylesColorsToHex = (
  styles: Record<string, any> | undefined
) => {
  if (!styles) return undefined;
  return Object.fromEntries(
    Object.entries(styles).map(([key, value]) => [
      key,
      typeof value === "string" && value.startsWith("rgb")
        ? convertRGB2Hex(value)
        : value
    ])
  );
};

export const convertValueMappingColorsToHex = (
  valueMapping: Array<Record<string, any>> | undefined
) => {
  if (!Array.isArray(valueMapping)) return undefined;
  return valueMapping.map((v) => ({
    ...v,
    backgroundColor: v.backgroundColor
      ? convertRGB2Hex(v.backgroundColor)
      : v.backgroundColor,
    fontColor: v.fontColor ? convertRGB2Hex(v.fontColor) : v.fontColor
  }));
};
