import React from "react";
import {
  DeleteButton,
  ActivateButton,
  DeactivateButton,
  SuspendButton
} from "components";
import { useDispatch } from "react-redux";
import {
  activateAction,
  deactivateAction,
  destroyAction,
  suspendAction
} from "../actions";
import { Action, ActionKey, ActionPayload, ActionsProps } from "../models";
import { getActionStatus } from "../utils/helpers";
import { Modal } from "./ConfirmationModal";

export const Actions: React.FC<ActionsProps> = ({ isIVS, keyOrIv }) => {
  const dispatchAction = useActionDispatcher();
  const keyOrIvID = isIVS ? keyOrIv?.satellite_iv_id : keyOrIv.satellite_key_id;
  return (
    <>
      {actionKeys.map(({ label, component: ActionButton }) => (
        <Modal
          key={label}
          onSubmit={(data: any) => {
            return dispatchAction(label, {
              type: isIVS ? "iv" : "key",
              id: isIVS ? data?.iv_id : data?.key_id,
              satId: data.satellite_instance_id
            });
          }}
          title={`${label} ${
            isIVS ? "Initialization Vector" : "Encryption Key"
          } with ID: ${keyOrIvID}?`}
          action={label}
          keyOrIv={keyOrIv}
          button={ActionButton}
        >
          {(onToggle: React.MouseEventHandler<HTMLButtonElement>) => {
            const isDisabled = !getActionStatus(label, keyOrIv.state);
            return (
              <ActionButton
                title={label}
                onClick={onToggle}
                ml={1}
                disabled={isDisabled}
              />
            );
          }}
        </Modal>
      ))}
    </>
  );
};

export const useActionDispatcher = () => {
  const dispatch = useDispatch();
  return (actionType: string, data: ActionPayload) => {
    switch (actionType) {
      case Action.ACTIVATE:
        dispatch(activateAction(data));
        break;
      case Action.SUSPEND:
        dispatch(suspendAction(data));
        break;
      case Action.DEACTIVATE:
        dispatch(deactivateAction(data));
        break;
      case Action.DESTROY:
        dispatch(destroyAction(data));
        break;
      default:
        console.warn(`Unknown action: ${actionType}`);
    }
  };
};

const actionKeys: ActionKey[] = [
  {
    label: Action.ACTIVATE,
    component: ActivateButton
  },
  {
    label: Action.SUSPEND,
    component: SuspendButton
  },
  {
    label: Action.DEACTIVATE,
    component: DeactivateButton
  },
  {
    label: Action.DESTROY,
    component: DeleteButton
  }
];
