import React from "react";
import { Alert } from "./HistoryTableRenderer";
import styled from "styled-components";
import palette from "styles/palette";
import { Box } from "primitives";
import { AlertValueStatus } from "app/alert/models";

interface AlertDetailProps {
  alert: Alert | null;
  position: {
    x: number;
    y: number;
  } | null;
}

const AlertDetailStyled = styled("div")<{ position: { x: number; y: number } }>`
  position: absolute;
  top: ${(props) => props.position.y}px;
  left: ${(props) => props.position.x}px;
  padding: 8px 8px;
  background-color: ${palette.palette.blue[3]};
  border: 1px solid white;
  font-size: 12px;
  max-width: 300px;
  z-index: 10;
`;

const Monospace = styled("span")`
  font-family: monospace;
`;

export const AlertDetail = ({ alert, position }: AlertDetailProps) => {
  return (
    (alert && position && (
      <AlertDetailStyled id={"alert-detail"} position={position}>
        <Box mb={1}>
          <Monospace>TYPE: </Monospace>
          <span>{alert.type}</span>
        </Box>
        {(alert.type === AlertValueStatus.OutOfBoundsValue && (
          <>
            <Box mb={1}>
              <Monospace>Min value: </Monospace>
              <span>{alert.valueBoundary?.minValue}</span>
            </Box>
            <Box mb={1}>
              <Monospace>Max value: </Monospace>
              <span>{alert.valueBoundary?.maxValue}</span>
            </Box>
          </>
        )) || (
          <>
            <Box mb={1}>
              <Monospace>SEVERITY: </Monospace>
              <span>{alert.alertLevel.severity}</span>
            </Box>
            <Box mb={1}>
              <Monospace>CONDITION: </Monospace>
              <span>{alert.alertLevel.condition}</span>
            </Box>
          </>
        )}
      </AlertDetailStyled>
    )) || <></>
  );
};
