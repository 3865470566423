import {
  KeyManagerActionType,
  KeyManagerActions,
  KeyManagerStoreState
} from "../models";
import { encryptionKeyStateManager } from "../utils/helpers";

const initialState: KeyManagerStoreState = {
  encryptionKeys: [],
  initializationVectors: []
};

export const keyManagerReducer = (
  state: KeyManagerStoreState = initialState,
  action: KeyManagerActions
): KeyManagerStoreState => {
  switch (action.type) {
    case KeyManagerActionType.FetchEncryptionKey:
      return {
        ...state,
        encryptionKeys: state.encryptionKeys.map(
          (mk) => (mk.key_id === action.payload?.key_id && action.payload) || mk
        )
      };

    case KeyManagerActionType.FetchAllEncryptionKey:
      return {
        ...state,
        encryptionKeys: action.payload || state.encryptionKeys
      };

    case KeyManagerActionType.FetchAllIVs:
      return {
        ...state,
        initializationVectors: action.payload || state.initializationVectors
      };

    case KeyManagerActionType.SetEncryptionKeyState: {
      if (action.payload === null) return state;
      const encryptionKeys = encryptionKeyStateManager(
        action.payload?.encryption_key_id,
        state.encryptionKeys,
        action.payload.state
      );
      return {
        ...state,
        encryptionKeys
      };
    }

    default:
      return state;
  }
};
