import React, { createContext } from "react";
import { IContext, IContextActions } from "../models";

export const initialState: IContext = {
  contextMenu: null,
  changeStateDialog: null
};

export const reducer = (state: IContext, action: IContextActions): IContext => {
  switch (action.type) {
    case "contextMenu":
      return {
        ...state,
        contextMenu: action.payload as IContext["contextMenu"]
      };
    case "changeStateDialog":
      return {
        ...state,
        changeStateDialog: action.payload as IContext["changeStateDialog"]
      };
    default:
      return { ...state, ...(action.payload as any) };
  }
};

export const KeyManagerContext = createContext<{
  state: IContext;
  dispatch: React.Dispatch<IContextActions>;
}>({ state: initialState, dispatch: () => null });
