import React from "react";
import styled from "styled-components";
import { size, color, ColorProps } from "styled-system";

export interface SvgProps extends ColorProps {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  animate?: string;
  dataTestId?: string;
  id?: string;
  muted?: boolean
}

const Svg = styled("svg")<React.SVGProps<SVGSVGElement>>(
  {
    display: "block",
    flex: "none",
    fill: "currentColor"
  },
  size,
  color
);

Svg.displayName = "Svg";

export default Svg;
