import { Dispatch } from "react";
import {
  GetAllTimelineAction,
  GetCalendarTimelineAction,
  FetchAllTimelineParams,
  FetchTimelineParams,
  FetchPastTimelineParams,
  PlanningSystemActionType,
  GetAllTimelineActionResult,
  GetPastTimelineActionResult,
  GetCalendarTimelineActionResult,
  GetTimelineActionResult,
  GetTimelineAction,
  UpdateProposedTimelineResult,
  FetchTimelineResponse,
  FetchAllOperationActivityParams,
  GetAllOperationActivitiesResult,
  DisapproveTimelineResult,
  ApproveTimelineResult,
  GetAllOperationActivitiesAction,
  GetPastTimelineAction,
  CreateOaParams,
  CreateOaResult,
  CreateOaAction,
  ResponseStatus,
  PatchOaParams,
  EditOaResult,
  EditOaAction,
  MultiResponseError,
  SingleResponseError,
  DisapproveTimelineAction,
  ApproveTimelineAction,
  DisapproveParams,
  TimelineEntry,
  UpdateProposedTimelineAction,
  TimelineTypes
} from "app/planningSystem/models";
import {
  fetchAllTimeline,
  disapproveMasterTimeline,
  approveProposedTimeline,
  updateProposedTimeline,
  fetchTimeline
} from "app/planningSystem/services/timeline";
import {
  fetchAllOperationActivity,
  createOperationActivity,
  editOperationActivity,
  deleteOperationActivity
} from "app/planningSystem/services/operationActivity";
import { timelineToCalendarTimeline } from "app/planningSystem/utils/helpers";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";

/**
 * @description request a single timeline type based on the passed parameter from the APIs
 * @param params
 */
export const getTimelineAction = (
  params: FetchTimelineParams
): GetTimelineActionResult => {
  return async (dispatch: Dispatch<GetTimelineAction>) => {
    try {
      const payload = await fetchTimeline(params);

      if (payload && "details" in payload) {
        const errorMessage =
          (payload as SingleResponseError).details || "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
        return Promise.resolve(null);
      }

      dispatch({
        type: PlanningSystemActionType.GetTimeline,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.resolve(null);
    }
  };
};

/**
 * @description request all the timeline types from the APIs
 * @param params
 */
export const getAllTimelineAction = (
  params: FetchAllTimelineParams
): GetAllTimelineActionResult => {
  return async (dispatch: Dispatch<GetAllTimelineAction>) => {
    try {
      const payload = await fetchAllTimeline(params);
      dispatch({
        type: PlanningSystemActionType.GetAllTimeline,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.resolve(null);
    }
  };
};

/**
 * @description takes the timelines from the store,
 * remap them to CalendarTimeline (used to be rendered in the timeline)
 * and save them into the store
 * @param timelines
 */
export const getCalendarTimelineAction = (
  timelines: FetchTimelineResponse
): GetCalendarTimelineActionResult => {
  return async (dispatch: Dispatch<GetCalendarTimelineAction>) => {
    try {
      const payload = await timelineToCalendarTimeline(timelines);
      dispatch({
        type: PlanningSystemActionType.GetCalendarTimeline,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.resolve([]);
    }
  };
};

export const getAllOperationActivitiesAction = (
  params?: FetchAllOperationActivityParams
): GetAllOperationActivitiesResult => {
  return async (dispatch: Dispatch<GetAllOperationActivitiesAction>) => {
    try {
      const payload = await fetchAllOperationActivity(params);
      dispatch({
        type: PlanningSystemActionType.GetOperationActivities,
        payload
      });
      return payload;
    } catch (e) {
      console.log(e);
      return Promise.resolve({ oas: [], pagination: {} });
    }
  };
};

export const createOperationActivityAction = (
  params: CreateOaParams
): CreateOaResult => {
  return async (dispatch: Dispatch<CreateOaAction>) => {
    try {
      const payload = await createOperationActivity(params);

      if (payload && "status" in payload) {
        if (payload.status === ResponseStatus.OK) {
          store.dispatch(
            setFeedback(
              "Success",
              FeedbackStatus.SUCCESS,
              "Operation activity created"
            )
          );
        } else if (payload.status === ResponseStatus.CREATED_WITH_WARNINGS) {
          store.dispatch(
            setFeedback(
              "CREATED WITH WARNING",
              FeedbackStatus.WARNING,
              payload.warnings[0]
            )
          );
        }
      } else if (payload && "detail" in payload) {
        (payload as MultiResponseError).detail.forEach((detail) =>
          store.dispatch(setFeedback("Error", FeedbackStatus.ERROR, detail.msg))
        );
      } else if (payload && "details" in payload) {
        const errorMessage =
          (payload as SingleResponseError).details || "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
      }

      return payload;
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }
  };
};

export const editOperationActivityAction = (
  uuid: string,
  params: PatchOaParams
): EditOaResult => {
  return async (dispatch: Dispatch<EditOaAction>) => {
    try {
      const { data, status } = await editOperationActivity(uuid, params);

      if (data && typeof data !== "string" && "details" in data) {
        const errorMessage =
          (data as SingleResponseError).details || "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
      } else if (data && typeof data !== "string" && "detail" in data) {
        (data as MultiResponseError).detail.forEach((detail) =>
          store.dispatch(setFeedback("Error", FeedbackStatus.ERROR, detail.msg))
        );
      } else if (status === 204 || status === 200) {
        store.dispatch(
          setFeedback(
            "Success",
            FeedbackStatus.SUCCESS,
            "Operation activity modified"
          )
        );
      }
      return data;
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }
  };
};

export const deleteOperationActivityAction = (uuid: string): EditOaResult => {
  return async (dispatch: Dispatch<EditOaAction>) => {
    try {
      const { data, status } = await deleteOperationActivity(uuid);

      if (data && typeof data !== "string" && "details" in data) {
        const errorMessage =
          (data as SingleResponseError).details || "Validation Error";
        store.dispatch(
          setFeedback("Error", FeedbackStatus.ERROR, errorMessage)
        );
      } else if (data && typeof data !== "string" && "detail" in data) {
        (data as MultiResponseError).detail.forEach((detail) =>
          store.dispatch(setFeedback("Error", FeedbackStatus.ERROR, detail.msg))
        );
      } else if (status === 204 || status === 200) {
        store.dispatch(
          setFeedback(
            "Success",
            FeedbackStatus.SUCCESS,
            "Operation activity deleted"
          )
        );
      }
      return data;
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }
  };
};

export const disapproveTimelineAction = (
  params: DisapproveParams
): DisapproveTimelineResult => {
  return async (dispatch: Dispatch<DisapproveTimelineAction>) => {
    try {
      const res = await disapproveMasterTimeline(params);
      if (res === 204) {
        await refreshTimelines(TimelineTypes.MASTER);
        store.dispatch(
          setFeedback(
            "Success",
            FeedbackStatus.SUCCESS,
            "Operation activity deleted"
          )
        );
      }
      return res;
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }
  };
};

export const approveTimelineAction = (): ApproveTimelineResult => {
  return async (dispatch: Dispatch<ApproveTimelineAction>) => {
    try {
      const res = await approveProposedTimeline();
      if (res === 204) {
        await refreshTimelines(TimelineTypes.PROPOSED);
        // Dispatching a `success` feedback
        store.dispatch(
          setFeedback(
            "Success",
            FeedbackStatus.SUCCESS,
            "Operation activity deleted"
          )
        );
      }
      return res;
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }
  };
};

export const updateProposedTimelineAction = (
  params: TimelineEntry[]
): UpdateProposedTimelineResult => {
  return async (dispatch: Dispatch<UpdateProposedTimelineAction>) => {
    try {
      const res = await updateProposedTimeline(params);
      if (res === 204) {
        await refreshTimelines(TimelineTypes.PROPOSED);
      }
      return res;
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }
  };
};

export const getPastTimelineAction = (
  params: FetchPastTimelineParams
): GetPastTimelineActionResult => {
  return async (dispatch: Dispatch<GetPastTimelineAction>) => {
    try {
      console.log("Getting past timeline items...");
      const payload = await fetchAllTimeline(params);
      if (payload && payload.timelines.length > 0) {
        console.log("Rendering past timeline items...");
        dispatch({
          type: PlanningSystemActionType.GetPastTimeline,
          payload: payload.timelines[0].entries
        });
      }
      return Promise.resolve(payload);
    } catch (e) {
      console.log(e);
      return Promise.resolve(null);
    }
  };
};

export const refreshTimelines = async (timelineType: TimelineTypes) => {
  const timelines = await store.dispatch(getTimelineAction(timelineType));
  await store.dispatch(getCalendarTimelineAction(timelines));
  store.dispatch(setFeedback("Success", FeedbackStatus.SUCCESS, "Updated"));
};
