import React from "react";
import { CancelButton } from "components";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";

export const Modal = ({ children, button: Button, ...props }: any) => {
  const submit = async (onToggle: () => void) => {
    props.onSubmit(props.keyOrIv);
    onToggle();
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          {children(onToggle)}
          <Dialog open={toggled} maxWidth="sm" data-testid="generic-modal">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>{props?.title}</DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center">
                        <Button
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          {props?.action}
                        </Button>
                        <CancelButton onClick={onToggle} mr={1}>
                          Cancel
                        </CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};
