import {
  SET_SOUND_FEEDBACK,
  SET_REFRESH_DATA_FALSE
} from "../models/constants";

const initialState = { alerts: null };

export const getUniqueAlertsByReceiveTimestamp = (alerts: any[]): any[] => {
  const seen = new Set<string>();

  return alerts.reduce((unique: any[], alert) => {
    const ts = alert?.receiveTimestamp;
    if (typeof ts === "string" && !seen.has(ts)) {
      seen.add(ts);
      unique.push(alert);
    }
    return unique;
  }, []);
};

const SoundFeedbackReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SOUND_FEEDBACK:
      const alerts = getUniqueAlertsByReceiveTimestamp(action.payload)
      return { ...state, alerts: alerts };
    case SET_REFRESH_DATA_FALSE:
      return false;
    default:
      return state;
  }
};

export default SoundFeedbackReducer;