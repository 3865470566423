import React, { Component, ChangeEvent } from "react";
import { connect } from "react-redux";
import {
  Cartesian3,
  Color,
  JulianDate,
  Cartographic,
  VelocityVectorProperty,
  Math,
  SceneMode
} from "cesium";
import {
  Viewer,
  Entity,
  Scene,
  Camera,
  Globe,
  CzmlDataSource,
  Clock,
  BillboardGraphics
} from "resium";
import TLEJS from "tle.js";

import { Box, Flex } from "primitives";
import { getPaddingForCustomAspectRatio } from "app/shared/utils";
import {
  CheckBox,
  Collapsible,
  InputField,
  SaveButton,
  CancelButton,
  EditButton,
  DragHandle
} from "components";
import {
  GroundStation,
  GroundStationConfiguration,
  fetchGroundStations,
  fetchGroundStationConfigList
} from "app/groundStation";
import { Passage } from "app/visibilityWindow/models";
import { fetchPassages } from "app/visibilityWindow/services";
import {
  SatelliteDefinition,
  getCZML,
  getTLE,
  setTLE
} from "app/satelliteDefinition";
import config from "config/constants";
import { getAllTLE } from "app/constellation/services";
import {
  GROUND_STATION_HIGHLIGHTED_IMAGE_URI,
  GROUND_STATION_HAS_PASSAGE_IMAGE_URI,
  GROUND_STATION_IMAGE_URI
} from "./helpers/images";
import {
  generateSatelliteEntityDescription,
  assignHexColorToCZML,
  changeCZMLBillboard,
  isValidTLE,
  parseTLE,
  generateCesiumColorFromHex,
  generateGroundStationEntityDescription
} from "./helpers/utils";
import {
  CesiumComponentBaseProps,
  CesiumComponentBaseState
} from "./helpers/models";
import { Container, CesiumConfig, Link } from "./helpers/styles";

const tlejs = new TLEJS();

export class CesiumComponentBase extends Component<
  CesiumComponentBaseProps,
  CesiumComponentBaseState
> {
  private groundStations: [];
  private groundStationConfigs: [];
  private satellites: any[];
  private setCurrentClockTimeInterval: any;
  private observerPosition: any;
  private containerRef: any;
  private viewerRef: any;
  private cameraRef: any;
  private padding: number;
  private passages: Passage[];

  constructor(props: CesiumComponentBaseProps) {
    super(props);
    this.state = {
      selectedGroundStations: [],
      selectedSatellites: [],
      editSatelliteTLE: null,
      tleValue: "",
      currentClockTime: new JulianDate.fromDate(new Date()),
      globeLoaded: false,
      highlightedGroundStations: []
    };

    this.groundStations = [];
    this.groundStationConfigs = [];
    this.satellites = [];
    navigator.geolocation.getCurrentPosition((position) => {
      this.observerPosition = position.coords;
    });
    this.containerRef = React.createRef();
    this.viewerRef = React.createRef();
    this.cameraRef = React.createRef();
    this.padding = 0;
    this.passages = [];
  }

  async fetchData() {
    const currentClockTime = new JulianDate.fromDate(new Date());
    const currentTimeMS = new Date().getTime();
    const { selectedSatellites } = this.state;
    const { options } = this.props;
    let updatedPassages: Passage[] = [];
    let index = 0;
    for (const sat of this.satellites) {
      if (selectedSatellites.indexOf(sat.id) !== -1) {
        const fetchPassagesResult = await fetchPassages(sat.id);
        updatedPassages = updatedPassages.concat(fetchPassagesResult);
        if (sat.loadedCzmlDataSource && sat.tle) {
          const entity = sat.loadedCzmlDataSource.entities.values[0];
          if (entity) {
            const cartesianPosition =
              entity.position.getValue(currentClockTime);
            if (!cartesianPosition) return;
            const cartographicPosition = new Cartographic.fromCartesian(
              cartesianPosition
            );
            const velocityVector = new VelocityVectorProperty(
              entity.position,
              false
            );
            const cartesianVelocity = velocityVector.getValue(currentClockTime);
            const velocityMagnitude = Cartesian3.magnitude(cartesianVelocity);

            let satInfo: any = {
              azimuth: null,
              elevation: null
            };

            if (sat.tle && sat.tle.tle && this.observerPosition) {
              try {
                satInfo = tlejs.getSatelliteInfo(
                  sat.tle.tle,
                  currentTimeMS,
                  this.observerPosition.latitude,
                  this.observerPosition.longitude,
                  0
                );
              } catch (e) {
                satInfo = {
                  azimuth: 0,
                  elevation: 0
                };
              }
            }

            const satelliteDescriptionData = {
              coordinates: {
                latitude: Math.toDegrees(cartographicPosition.latitude),
                longitude: Math.toDegrees(cartographicPosition.longitude),
                altitude: (cartographicPosition.height / 1000).toFixed(2)
              },
              velocity: (velocityMagnitude / 1000).toFixed(2),
              azimuth: satInfo.azimuth,
              elevation: satInfo.elevation,
              parsedTle: sat.tle.parsed,
              tle: sat.tle.tle
            };

            if (this.satellites[index].czml) {
              //Assign description to satellite entity
              this.satellites[index].czml[1].description =
                generateSatelliteEntityDescription(satelliteDescriptionData);

              //Assign groundstations to satellite entity
              this.satellites[index].czml[1].properties = {};
              let groundStations: any = "";
              this.groundStationConfigs.forEach((gsConfig: any) => {
                if (gsConfig.satelliteID === sat.id) {
                  groundStations += `${gsConfig.groundStationID},`;
                }
              });
              this.satellites[index].czml[1].properties.groundstations =
                groundStations;

              let satelliteOptions: any = null;
              if (options && options.satellites) {
                satelliteOptions = options.satellites.find(
                  (satOption: any) => satOption.id === sat.id
                );
                //Assign config options orbit color
                if (satelliteOptions && satelliteOptions.orbitColor) {
                  this.satellites[index].czml = assignHexColorToCZML(
                    this.satellites[index].czml,
                    satelliteOptions.orbitColor
                  );
                }
              }
              //Assign config options billboard object
              const hasPassage = updatedPassages.find(
                (passage: Passage) => passage.satelliteID === sat.id
              );
              this.satellites[index].czml = changeCZMLBillboard(
                this.satellites[index].czml,
                satelliteOptions ? satelliteOptions.object : null,
                hasPassage
              );

              sat.loadedCzmlDataSource
                .process(this.satellites[index].czml)
                //eslint-disable-next-line no-loop-func
                .then(() => {
                  if (!this.satellites[index].czml[1].properties.show) {
                    this.satellites[index].czml[1].properties.show = true;
                  }
                });
            }
          }
        }
      }
      index += 1;
    }
    this.passages = updatedPassages;
    this.setState({ currentClockTime });
  }

  async componentWillMount() {
    const { constellation, options } = this.props;

    const groundStations = await this.props.fetchGroundStations();
    this.groundStations = groundStations;
    const selectedGroundStations: number[] = [];
    groundStations.forEach((gs: GroundStation) => {
      if (
        !options ||
        !options.groundstations ||
        options.groundstations.find(
          (groundstation: any) => groundstation.id === gs.groundStationID
        )
      ) {
        selectedGroundStations.push(gs.groundStationID);
      }
    });

    const groundStationConfigs =
      await this.props.fetchGroundStationConfigList();
    this.groundStationConfigs = groundStationConfigs;

    const selectedSatellites: any = [];
    if (constellation && constellation.satelliteInstances) {
      const satellites = constellation.satelliteInstances;
      const promises = satellites.map(async (satellite: any) => {
        if (
          !options ||
          !options.satellites ||
          options.satellites.find((sat: any) => sat.id === satellite.id)
        ) {
          selectedSatellites.push(satellite.id);
        }
        const satelliteCZML = await this.props.getCZML(satellite.id);
        if (satelliteCZML !== null && satelliteCZML?.length === 2) {
          satellite.czml = satelliteCZML;
        }
        this.satellites.push(satellite);
      });
      await Promise.all(promises);

      const { data: satelliteTLEs } = await this.props.getAllTLE();
      this.satellites.forEach(async (satellite: any, index: number) => {
        const satelliteTLE = satelliteTLEs[satellite.id.toString()];
        if (satelliteTLE && satelliteTLE?.tle && isValidTLE(satelliteTLE.tle)) {
          satelliteTLE.parsed = parseTLE(satelliteTLE.tle);
          satellite.tle = satelliteTLE;
          this.satellites[index] = satellite;
        }
      });
    }

    this.setState({
      selectedGroundStations,
      selectedSatellites
    });

    this.padding = getPaddingForCustomAspectRatio(this.containerRef, 1.5);
  }

  componentWillUpdate(nextProps: CesiumComponentBaseProps) {
    const { refreshData } = this.props;
    if (refreshData !== nextProps.refreshData && !nextProps.refreshData) {
      this.setCurrentClockTimeInterval &&
        clearInterval(this.setCurrentClockTimeInterval);
    }
    if (refreshData !== nextProps.refreshData && nextProps.refreshData) {
      this.setCurrentClockTimeInterval = setInterval(
        () => this.fetchData(),
        config.timer.globe
      );
    }
  }

  componentDidUpdate(
    prevProps: CesiumComponentBaseProps,
    prevState: CesiumComponentBaseState
  ) {
    if (
      prevState.selectedSatellites.length !==
      this.state.selectedSatellites.length
    ) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.viewerRef.current.cesiumElement.entities.removeAll(true);
    this.viewerRef.current.cesiumElement.dataSources.removeAll(true);
    this.viewerRef.current.cesiumElement.destroy();
    this.setCurrentClockTimeInterval &&
      clearInterval(this.setCurrentClockTimeInterval);
  }

  componentDidMount() {
    //Remove Cesium Credits
    const creditsElements = document.getElementsByClassName(
      "cesium-widget-credits"
    ) as HTMLCollectionOf<HTMLElement>;
    if (creditsElements && creditsElements.length > 0) {
      for (const creditsElement of creditsElements) {
        creditsElement!.style.display = "none";
      }
    }
    this.fetchData();
    this.setCurrentClockTimeInterval = setInterval(
      () => this.fetchData(),
      config.timer.globe
    );
  }

  //Ground Stations
  hasGroundStationConfig = (groundStationID: number) => {
    return this.groundStationConfigs.some(
      (gsConfig: GroundStationConfiguration) =>
        gsConfig.groundStationID === groundStationID
    );
  };

  toggleGroundStation = (groundStationID: number) => {
    let { selectedGroundStations } = this.state;
    if (selectedGroundStations.includes(groundStationID as never)) {
      selectedGroundStations = selectedGroundStations.filter(
        (gs) => gs !== groundStationID
      );
    } else {
      selectedGroundStations.push(groundStationID);
    }
    this.setState({ selectedGroundStations });
  };

  selectAllGroundStations = () => {
    const selectedGroundStations = this.groundStations.map(
      (gs: GroundStation) => gs.groundStationID
    );
    this.setState({ selectedGroundStations });
  };

  deselectAllGroundStations = () => {
    this.setState({ selectedGroundStations: [] });
  };

  //Satellites
  toggleSatellite = (satelliteID: number) => {
    let { selectedSatellites } = this.state;
    if (selectedSatellites.includes(satelliteID as never)) {
      selectedSatellites = selectedSatellites.filter(
        (satellite: number) => satellite !== satelliteID
      );
    } else {
      selectedSatellites.push(satelliteID);
    }
    this.setState({ selectedSatellites });
  };

  selectAllSatellites = () => {
    const selectedSatellites = this.satellites.map(
      (sat: SatelliteDefinition) => sat.id
    );
    this.setState({ selectedSatellites });
  };

  deselectAllSatellites = () => {
    this.setState({ selectedSatellites: [] });
  };

  setEditSatelliteTLE = (
    satelliteID: number | null,
    tleValue: string | undefined
  ) => {
    this.setState({ editSatelliteTLE: satelliteID, tleValue });
  };

  saveSatelliteTLE = async () => {
    const { editSatelliteTLE, tleValue } = this.state;
    if (tleValue && isValidTLE(tleValue)) {
      const satelliteID = editSatelliteTLE;
      const data: any = {
        satelliteID: satelliteID,
        tle: tleValue
      };
      await this.props.setTLE(satelliteID, data);
      const satelliteCZML = await this.props.getCZML(satelliteID);
      this.satellites.forEach((satellite, index) => {
        if (satellite.id === satelliteID) {
          this.satellites[index].czml = satelliteCZML;
          data.parsed = parseTLE(tleValue);
          this.satellites[index].tle = data;
          return;
        }
      });
      this.setState({ editSatelliteTLE: null });
    }
  };

  getDefaultSceneMode() {
    const { options } = this.props;
    switch (options.view) {
      case "3D":
        return SceneMode.SCENE3D;
      case "2D":
        return SceneMode.SCENE2D;
      case "Colombus":
        return SceneMode.COLUMBUS_VIEW;
      default:
        return SceneMode.SCENE3D;
    }
  }

  render() {
    const {
      selectedGroundStations,
      selectedSatellites,
      editSatelliteTLE,
      currentClockTime,
      globeLoaded,
      highlightedGroundStations,
      tleValue
    } = this.state;
    const { options } = this.props;
    const passages = this.passages;

    return (
      <>
        <DragHandle absolute label={false} />
        <Container
          data-testid="globe"
          ref={this.containerRef}
          pl={this.padding ? `${this.padding / 2}%` : 0}
          pr={this.padding ? `${this.padding / 2}%` : 0}
          bg="palette.black.0"
        >
          <Viewer
            ref={this.viewerRef}
            animation={false}
            fullscreenButton={false}
            vrButton={false}
            timeline={false}
            baseLayerPicker={false}
            geocoder={false}
            homeButton={false}
            navigationHelpButton={false}
            style={{ height: "100%" }}
            sceneMode={this.getDefaultSceneMode()}
            onSelectedEntityChange={() => {
              if (
                this.viewerRef.current.cesiumElement.selectedEntity &&
                this.viewerRef.current.cesiumElement.selectedEntity
                  .properties &&
                this.viewerRef.current.cesiumElement.selectedEntity.properties
                  .groundstations
              ) {
                const newHighlightedGroundStations =
                  this.viewerRef.current.cesiumElement.selectedEntity.properties.groundstations
                    .getValue(new JulianDate.fromDate(new Date()))
                    .split(",");
                newHighlightedGroundStations.pop();
                this.setState({
                  highlightedGroundStations: newHighlightedGroundStations
                });
              } else {
                this.setState({
                  highlightedGroundStations: null
                });
              }
            }}
          >
            <Scene
              onMorphComplete={() => {
                return (event: any) => {
                  setTimeout(() => {
                    if (event && event.previousMode !== 2)
                      this.cameraRef.current.cesiumElement.flyTo({
                        destination: Cartesian3.fromDegrees(0, 0, 39550000.0),
                        duration: 0.25
                      });
                  }, 100);
                };
              }}
            />
            <Globe
              onTileLoadProgress={(currentLoadQueueLength: number) =>
                currentLoadQueueLength === 0 &&
                this.setState({ globeLoaded: true })
              }
            />
            <Camera ref={this.cameraRef} defaultZoomAmount={0} />
            {globeLoaded &&
              this.groundStations.map((groundStation: GroundStation) => {
                if (
                  selectedGroundStations.includes(
                    groundStation.groundStationID as never
                  )
                ) {
                  let optionsGroundStationColor = null;
                  let showBillboard = false;
                  if (options && options.groundstations) {
                    const groundstationOptions = options.groundstations.find(
                      (gs: any) => gs.id === groundStation.groundStationID
                    );
                    //Assign config options color
                    if (groundstationOptions && groundstationOptions.color) {
                      optionsGroundStationColor = generateCesiumColorFromHex(
                        groundstationOptions.color
                      );
                    }
                    //Assign config options billboard object
                    if (
                      groundstationOptions &&
                      groundstationOptions.object &&
                      groundstationOptions.object === "groundstation"
                    ) {
                      showBillboard = true;
                    }
                  }

                  const hasPassage = passages.find(
                    (passage: Passage) =>
                      passage.groundStationID === groundStation.groundStationID
                  );
                  const isHighlighted =
                    highlightedGroundStations &&
                    highlightedGroundStations.indexOf(
                      groundStation.groundStationID.toString()
                    ) !== -1;

                  return (
                    <Entity
                      key={groundStation.groundStationID}
                      name={groundStation.groundStationName}
                      position={Cartesian3.fromDegrees(
                        groundStation.coordinates.longitude,
                        groundStation.coordinates.latitude,
                        groundStation.coordinates.elevation
                      )}
                      point={{
                        show: showBillboard ? false : true,
                        pixelSize: hasPassage ? 12 : 10,
                        outlineColor: new Color(1, 1, 0, 0.8),
                        outlineWidth: isHighlighted ? 3 : 0,
                        color: hasPassage
                          ? new Color(0, 1, 0, 1)
                          : optionsGroundStationColor
                          ? optionsGroundStationColor
                          : this.hasGroundStationConfig(
                              groundStation.groundStationID
                            )
                          ? new Color(0, 0.7, 0, 0.7)
                          : new Color(1, 1, 1, 0.7)
                      }}
                      description={generateGroundStationEntityDescription(
                        groundStation
                      )}
                    >
                      <BillboardGraphics
                        image={
                          isHighlighted
                            ? GROUND_STATION_HIGHLIGHTED_IMAGE_URI
                            : hasPassage
                            ? GROUND_STATION_HAS_PASSAGE_IMAGE_URI
                            : GROUND_STATION_IMAGE_URI
                        }
                        show={showBillboard}
                        scale={0.8}
                      />
                    </Entity>
                  );
                } else {
                  return null;
                }
              })}
            {globeLoaded &&
              this.satellites.map((satellite: any, index: number) => {
                if (
                  selectedSatellites.includes(satellite.id as never) &&
                  satellite.czml
                ) {
                  return (
                    <CzmlDataSource
                      key={satellite.id}
                      data={satellite.czml}
                      onLoad={(loadedCzmlDataSource: any) => {
                        this.satellites[index].loadedCzmlDataSource =
                          loadedCzmlDataSource;
                      }}
                      show={
                        satellite.czml[1]?.properties &&
                        satellite.czml[1]?.properties.show === true
                          ? true
                          : false
                      }
                    />
                  );
                } else {
                  return null;
                }
              })}
            <Clock
              startTime={currentClockTime}
              currentTime={currentClockTime}
            />
          </Viewer>
          <Flex>
            <CesiumConfig
              width={
                editSatelliteTLE
                  ? this.padding
                    ? `${100 - this.padding}%`
                    : "100%"
                  : ""
              }
            >
              <Collapsible
                label="Satellites"
                onCollapse={() => this.setEditSatelliteTLE(null, "")}
              >
                {this.satellites.map((satellite: any) => {
                  return (
                    <Box key={satellite.id}>
                      <Flex alignItems="center">
                        <CheckBox
                          checked={selectedSatellites.includes(
                            satellite.id as never
                          )}
                          label={satellite.label}
                          onChange={() => this.toggleSatellite(satellite.id)}
                        />
                        {satellite.id !== editSatelliteTLE ? (
                          <EditButton
                            onClick={() =>
                              this.setEditSatelliteTLE(
                                satellite.id,
                                satellite.tle ? satellite.tle.tle : ""
                              )
                            }
                            height="26px"
                            mr={2}
                          >
                            Edit TLE
                          </EditButton>
                        ) : null}
                      </Flex>
                      {satellite.id === editSatelliteTLE ? (
                        <Flex flexDirection="column" p={2}>
                          <InputField
                            id="input-lte"
                            multiline
                            rows={3}
                            onChange={(
                              event:
                                | ChangeEvent<HTMLTextAreaElement>
                                | ChangeEvent<HTMLInputElement>
                            ) =>
                              this.setState({ tleValue: event.target.value })
                            }
                            value={tleValue}
                          />
                          <Flex my={2} justifyContent="flex-end">
                            <SaveButton
                              mx={2}
                              onClick={() => this.saveSatelliteTLE()}
                            >
                              Save
                            </SaveButton>
                            <CancelButton
                              mx={2}
                              onClick={() => this.setEditSatelliteTLE(null, "")}
                            >
                              Cancel
                            </CancelButton>
                          </Flex>
                        </Flex>
                      ) : null}
                    </Box>
                  );
                })}
                <Flex>
                  <Link onClick={() => this.selectAllSatellites()}>
                    Select All
                  </Link>
                  <Link onClick={() => this.deselectAllSatellites()}>
                    Deselect All
                  </Link>
                </Flex>
              </Collapsible>

              <Collapsible label="Ground Stations">
                {this.groundStations.map((groundStation: GroundStation) => {
                  return (
                    <CheckBox
                      key={groundStation.groundStationID}
                      checked={selectedGroundStations.includes(
                        groundStation.groundStationID as never
                      )}
                      label={groundStation.groundStationName}
                      labelColor={
                        this.hasGroundStationConfig(
                          groundStation.groundStationID
                        )
                          ? new Color(0, 1, 0, 1).toCssColorString()
                          : new Color(1, 1, 1, 1).toCssColorString()
                      }
                      onChange={() =>
                        this.toggleGroundStation(groundStation.groundStationID)
                      }
                    />
                  );
                })}
                <Flex>
                  <Link onClick={() => this.selectAllGroundStations()}>
                    Select All
                  </Link>
                  <Link onClick={() => this.deselectAllGroundStations()}>
                    Deselect All
                  </Link>
                </Flex>
              </Collapsible>
            </CesiumConfig>
          </Flex>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  constellation: state.constellations.selected,
  refreshData: state.app.refreshData
});

const mapDispatchToProps = () => {
  return {
    fetchGroundStations: () => fetchGroundStations(),
    fetchGroundStationConfigList: () => fetchGroundStationConfigList(),
    getCZML: (satelliteID: any) => getCZML(satelliteID),
    getTLE: (satelliteID: any) => getTLE(satelliteID),
    setTLE: (satelliteID: any, data: any) => setTLE(satelliteID, data),
    getAllTLE: () => getAllTLE()
  };
};

export const Cesium = connect(
  mapStateToProps,
  mapDispatchToProps
)(CesiumComponentBase);
