import { store } from "app/store";
import * as ActionTypes from "../models/constants";
import { FetchTelemetryActionTypes } from "../models/telemetry";
/* dashboards */
export const addDashboard = (payload) => ({
  payload,
  type: ActionTypes.ADD_DASHBOARD
});

export const addDashboards = (payload) => ({
  payload,
  type: ActionTypes.ADD_DASHBOARDS
});

export const updateDashboard = (payload) => ({
  payload,
  type: ActionTypes.UPDATE_DASHBOARD
});

export const removeDashboard = (payload) => ({
  payload,
  type: ActionTypes.REMOVE_DASHBOARD
});

export const selectDashboard = (payload) => ({
  payload,
  type: ActionTypes.SELECT_DASHBOARD
});

export const addExtentToGraph = (payload) => ({
  payload,
  type: ActionTypes.ADD_EXTENT_TO_GRAPH
});

export const removeAllExtents = () => ({
  type: ActionTypes.REMOVE_ALL_EXTENTS
});

export const updateTMOnWorkerMessage = (event) => {
  store.dispatch({
    type: FetchTelemetryActionTypes.UPDATE_HISTORICAL_TM_DATA_BY_WORKER,
    payload: event.data
  });
};


export const addSoundNotification = (alert) => {
  store.dispatch({
    type: 'SET_SOUND_FEEDBACK',
    payload: alert
  });
};
