import styled from "styled-components";
import { themeGet } from "styled-system";
import { Box, Flex } from "primitives";

export const Container = styled(Box)`
  position: relative;
  height: 100%;
`;

export const CesiumConfig = styled(Box)`
  position: absolute;
  top: 10px;
  background-color: rgba(39, 41, 58, 0.6);
`;

export const Link = styled("span")`
  color: ${themeGet("colors.palette.brand.0")};
  font-size: 12px;
  margin: 5px;
  text-decoration: underline;
  cursor: pointer;
`;
