import React, { useCallback } from "react";
import { SuspenseMutation } from "app/network";
import { Toggler, Spinner } from "components";
import { CancelButton, ActivateButton } from "components";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Text
} from "primitives";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "app/shared";
import { bookingOnePassage } from "app/passageManager/actions";
import { bookSchemaEdit, bookSchemaUISchemEdit } from "./bookCandidateSchema";
import { getFromLocalStorage } from "app/passageManager/utils";

interface BookCandidateContainerProps {
  closeModal?: any;
  candidate?: any;
  bookingConfigId?: any;
  bookable?: any;
}

export const BookCandidate = ({
  candidate,
  bookingConfigId,
  bookable
}: BookCandidateContainerProps) => {
  const dispatch = useDispatch();
  const { status, presets } = useSelector((s: any) => s.passageManager);
  const presetsBySat = !presets ? [] : Object.keys(presets)?.filter(satId => Number(satId) === candidate.satelliteID)
    .flatMap(key => presets[key]);

  const loading = status?.book === "loading";
  const bookFormData = {};
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    onToggle: any
  ) => {
    e.preventDefault();
    const providerName =
      candidate?.additionalInfo?.provider?.providerName || "UNKNOWN";
    const selectedPreset = presetsBySat?.find(p => p.id === formData.presets);
    const configId = bookingConfigId ? bookingConfigId : getFromLocalStorage("bookingConfig")?.id
    const bookingPayload = [
      {
        providerID: candidate.providerID,
        providerName: providerName,
        satelliteID: candidate.satelliteID,
        passageID: candidate.passageID,
        groundStationName: candidate.groundStationName,
        bookingConfigurationID: configId,
        preset: selectedPreset
      }
    ];
    const result = await dispatch(
      bookingOnePassage(bookingPayload, bookingConfigId)
    );
    if (result?.length || !loading) {
      onToggle();
    }
  };

  const transformData = (formData: any) => {
    // TODO: map presets IDs when configured
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  return (
    <Toggler>
      {([toggled, onToggle]) => {
        return (
          <>
            <ActivateButton
              onClick={onToggle}
              disabled={!bookable}
              data-testid="passage-book-btn"
            >
              BOOK
            </ActivateButton>
            <Dialog
              open={toggled}
              maxWidth="lg"
              data-testid="passage-manager-edit"
            >
              <SuspenseMutation>
                {({ loading, action }) => {
                  return (
                    <>
                      <DialogTitle>
                        Book candidate with id: {candidate?.passageID}?
                      </DialogTitle>
                      <DialogContent>
                        <Flex alignItems="center">
                          <Form
                            id="passage-manager"
                            data-testid="candidate-book-form"
                            formData={bookFormData}
                            schema={bookSchemaEdit(presetsBySat)}
                            uiSchema={bookSchemaUISchemEdit}
                            disabled={loading}
                            onSubmit={(
                              form: { formData: any; },
                              e: React.FormEvent<HTMLInputElement>
                            ) => {
                              return action(() => submit(e, form.formData, onToggle)
                              );
                            }}
                            onChange={(form: any) => onChange(form)}
                          >
                            <></>
                          </Form>
                        </Flex>
                      </DialogContent>
                      <DialogActions>
                        <CancelButton onClick={onToggle}>CANCEL</CancelButton>
                        <Button
                          form="passage-manager"
                          type="submit"
                          ml={2}
                          data-testid="book-btn"
                        >
                          {loading ? (
                            <Flex flexDirection="row" mx={2}>
                              <Spinner size={14} />
                              <Text color="text.default" mx={2}>
                                BOOKING....
                              </Text>
                            </Flex>
                          ) : (
                            <Text color="text.default"> BOOK PASSAGE </Text>
                          )}
                        </Button>
                      </DialogActions>
                    </>
                  );
                }}
              </SuspenseMutation>
            </Dialog>
          </>
        );
      }}
    </Toggler>
  );
};

export const BookCandidateContainer = BookCandidate;
