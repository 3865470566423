import React from "react";
import { Severity } from "app/alert/models";
import { TableCell } from "components";
import { Text } from "primitives";
import styled from "styled-components";
import { Alert } from "./HistoryTableRenderer";
import { AlertValueStatus } from "app/alert/models";

interface DataSourceTableCellProps {
  sourceValue: {
    valueHumanReadable: string | null;
    value: Array<Source>;
  };
  parentRow: string;
  handleTooltip: (
    e: React.MouseEvent<HTMLSpanElement>,
    alert: Alert,
    parentRow: string
  ) => void;
}

interface Source {
  index: any;
  value: any;
  alert: Alert[];
}

const AlertedValue = styled(Text)`
  position: relative;
  padding: 8px 8px;
  user-select: all;
  &:hover {
    background-color: rgba(122, 185, 219, 0.1);
  }
  &.alerted-value:hover {
    cursor: pointer;
  }
`;

export const DataSourceTableCell = ({
  sourceValue,
  handleTooltip,
  parentRow
}: DataSourceTableCellProps) => {
  return (
    <TableCell>
      {sourceValue.valueHumanReadable ||
        (Array.isArray(sourceValue.value)
          ? sourceValue.value.map(({ alert, value }, idx) => {
              const separator =
                idx + 1 !== sourceValue.value.length ? ", " : "";

              // Case that value has no alert
              if (!Array.isArray(alert) || alert.length === 0)
                return (
                  <AlertedValue
                    key={idx}
                  >{`${value}${separator}`}</AlertedValue>
                );

              const [latestAlert] = alert;

              // Case that value has a NormalValue Alert
              if (latestAlert.type === AlertValueStatus.NormalValue)
                return (
                  <AlertedValue
                    key={idx}
                  >{`${value}${separator}`}</AlertedValue>
                );

              // All other alerts
              const alertSeverity =
                (latestAlert.alertLevel?.severity === Severity.Warning &&
                  "text.warning") ||
                (latestAlert.alertLevel?.severity === Severity.Critical &&
                  "text.danger") ||
                (latestAlert.type === AlertValueStatus.OutOfBoundsValue &&
                  "palette.purple.1") ||
                "";

              return (
                <AlertedValue
                  onClick={(e) => handleTooltip(e, latestAlert, parentRow)}
                  className={"alerted-value"}
                  key={idx}
                  color={alertSeverity}
                >{`${value}${separator}`}</AlertedValue>
              );
            })
          : sourceValue.value)}
    </TableCell>
  );
};
