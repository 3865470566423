import React, { Component, Fragment } from "react";
import { constants } from "config";

/**
 * DataSourceReading component
 * Responsible for get reading for a given data source repeatedly
 * TODO: split the component
 */
export class DataSourceReading extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.willUnmount = false;
    this.state = {
      datasourceReading: null,
      nextPageUrl: null,
      previousPageUrl: null
    };
  }

  /** If no timeout for any reason when component update,
   * the component start to call the api again */
  componentDidUpdate() {
    const { refreshData, autoUpdates } = this.props;
    if (!this.timeout && refreshData && autoUpdates) {
      this.startDatasourceReading();
    }
  }

  componentDidMount() {
    this.startDatasourceReading();
  }

  componentWillUnmount() {
    this.clean();
    this.willUnmount = true;
  }

  componentWillUpdate(nextProps) {
    const { refreshData, autoUpdates, params } = this.props;
    if (
      (refreshData !== nextProps.refreshData && !nextProps.refreshData) ||
      (autoUpdates && !nextProps.autoUpdates) ||
      nextProps.clearFetchInterval !== this.props.clearFetchInterval ||
      params?.paginationUrl !== nextProps.params?.paginationUrl ||
      params?.pageSize !== nextProps.params?.pageSize
    ) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (
      refreshData !== nextProps.refreshData &&
      nextProps.refreshData &&
      !this.timeout &&
      nextProps.clearFetchInterval !== this.props.clearFetchInterval
    ) {
      this.startDatasourceReading();
    }
  }

  /**
   * @returns {boolean}
   * true if has satellite and valid ids
   */
  hasValidIdsParams() {
    const { satellite, ids } = this.props;
    return satellite && ids && ids.every(Boolean);
  }

  clean() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  componentDidCatch() {
    this.clean();
  }

  /**
   * Start reading by system or ids individually
   */
  startDatasourceReading() {
    const options = this.props.options;
    const widgetName = options && options.label ? options.label : "";

    const isGloballyControlled = options && options.isGloballyControlled;
    // const isRealTimeGraph =
    //   this.props.options && this.props.options.liveStream !== undefined;
    // const fetchLocally = this.props.options && this.props.options.fetchLocally;

    // check if widget config has bool for enabling global override of fetching

    // Note: realtime graph fetches last two hours(120) on first fetch and 2 seconds(2000) updatePeriod on subsequent fetches
    // const isRealTimeGraph = this.props.options.liveStream !== undefined;

    if (isGloballyControlled) {
      // console.log(`${widgetName} widget is globally controlled`);
      return;
    }

    if (!this.hasValidIdsParams()) return;
    console.log(`${widgetName} widget is fetching data locally`);
    if (this.props.multiple) {
      this.getReadingMultiple(0);
      return;
    }

    this.getReadingByIds(0);
  }

  /**
   *
   * @param {number} initialInterval
   * The first call has initialInterval = 0
   * the next ones have a periodic interval
   */
  getReadingByIds(initialInterval) {
    const { satellite, ids, interval, params, autoUpdates, options } =
      this.props;

    this.timeout = setTimeout(() => {
      this.props
        .getReadingsByDataSources(satellite, ids, params || {}, options)
        .then((datasourceReading) => {
          if (datasourceReading) {
            if (!this.willUnmount && autoUpdates) {
              this.setState({ datasourceReading });
            }
          }
        })
        .catch((error) => {
          this.setState({ error });
          this.clean();
        });
      if (!this.willUnmount && autoUpdates) {
        this.getReadingByIds(
          interval || constants.timer.defaultDataSourceReading
        );
      } else {
        this.clean();
      }
    }, initialInterval);
  }

  /**
   *
   * @param {number} initialInterval
   * The first call has initialInterval = 0
   * the next ones have a periodic interval
   */
  getReadingMultiple(initialInterval) {
    const { satellite, ids, interval, params, options } = this.props;
    const { paginationUrl, ...restParams } = params;

    const filter = {
      ...restParams,
      datasources: ids.join(",")
    };

    this.timeout = setTimeout(() => {
      this.props
        .getReadingsMultipleDataSources(
          satellite,
          {
            filter,
            paginationUrl
          },
          options
        )
        .then((response) => {
          const { data, nextPageUrl, previousPageUrl } = response;
          if (!this.willUnmount && data) {
            this.setState({
              datasourceReading: data,
              nextPageUrl,
              previousPageUrl
            });
          }
        })
        .catch((error) => {
          this.setState({ error });
          this.clean();
        })
        .finally(() => {
          if (
            !this.willUnmount &&
            (!params.from || !params.to) &&
            this.props.autoUpdates
          ) {
            this.getReadingMultiple(
              interval || constants.timer.defaultDataSourceReading
            );
          } else {
            this.clean();
          }
        });
    }, initialInterval);
  }

  render() {
    const { children, currentReading, options, historicalReadings } =
      this.props;

    const {
      datasourceReading: _datasourceReading,
      nextPageUrl,
      previousPageUrl
    } = this.state;

    // Note:  options.showSearh/liveStream conditionals can be removed once all dashboards are updated with widgetName property
    const isRealTimeGraph =
      (options && options.widgetName === "RealTimeGraph") ||
      (options && options.liveStream !== undefined);
    const isRealTimeTable =
      (options && options.widgetName === "RealTimeTable") ||
      (options && options.showSearch !== undefined);

    let datasourceReading = [];
    if (!children) return null;

    // If widget config has bool (isGloballyControlled?), disable local fetching and use telemetry from datastore. This enables backward compatibility while allowing local and global control for fetching telemetry

    const filteredReadingsForWidget = (options, historicalReadings) => {
      if (!options || !historicalReadings) {
        // console.log(
        //   `options: ${options} historicalReadings:${historicalReadings}`
        // );
        return [];
      }
      const dataSourcesInWidget = options.dataSources.map((i) => i.id);
      // debugger; // eslint-disable-line no-debugger
      const filtered =
        Array.isArray(historicalReadings) &&
        historicalReadings.length &&
        historicalReadings.filter((r) =>
          dataSourcesInWidget.includes(r.dataSource.id)
        );
      return filtered;
    };
    const getDatasourceReadings = () => {
      if (isRealTimeGraph) {
        return historicalReadings;
        // return filteredReadingsForWidget(options, historicalReadings);
      } else if (isRealTimeTable) {
        return currentReading;
        // return filteredReadingsForWidget(options, currentReading);
      } else {
        // if not realtime graph, pass latest readings to widgets
        return currentReading;
      }
    };

    datasourceReading =
      (options && getDatasourceReadings()) || _datasourceReading;

    return (
      <Fragment>
        {children({ datasourceReading, nextPageUrl, previousPageUrl })}
      </Fragment>
    );
  }
}

DataSourceReading.defaultProps = {
  autoUpdates: true
};
