import { dataProvider, DELETE, CREATE, UPDATE, GET_LIST } from "app/network";
import { passageManagerDataProvider } from "app/network/dataProvider";

export const getBookerConfigs = (pagination: any) => {
  const page = pagination?.page ?? 0;
  const limit = pagination?.pageSize || 10;
  const offset = page * limit;

  return passageManagerDataProvider(
    GET_LIST,
    `configurations?limit=${limit}&offset=${offset}`
  );
};

export const createBookerConfig = async (data: any) => {
  const { data: data_1 } = await passageManagerDataProvider(
    CREATE,
    `configurations`,
    { data }
  );
  return data_1;
};

export const updateBookerConfig = async (id: any, data: any) => {
  const { data: data_1 } = await passageManagerDataProvider(
    UPDATE,
    `configurations`,
    { id, data }
  );
  return data_1;
};

export const deleteBookerConfig = async (id: any) => {
  return await passageManagerDataProvider(DELETE, `configurations`, { id });
};
