import React, { Component, Fragment } from "react";
import { DashboardComponents } from "app/dashboard/models";
import { getAlertColor, getAlertColorForArrayValue } from "app/alert/helpers";
import {
  configOptionsGetTimeReferenceLabel,
  configOptionsGetTimeReferenceValue
} from "app/telemetry/utils";
import { Text, Flex, Icon, Absolute } from "primitives";
import { Tooltip, DragHandle } from "components";
import { clone } from "utils";
import {
  TelemetryBaseComponent,
  DateTimeComponent
} from "app/telemetry/visualizations";
import { scaleSimpleValue } from "../shared/utils";

const toFixedCustom = (value, fixed) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return value.toString().match(re)[0];
};

const convertValue = (value, options) => {
  const { valueConversion, scientificNotation, floatDigits } = options;
  switch (valueConversion) {
    case "Hexadecimal":
      return `0x${value.toString(16)}`;
    case "Octal":
      return `0${value.toString(8)}`;
    case "Binary":
      return `0b${value.toString(2)}`;
    default:
      if (!isNaN(value) && scientificNotation && scientificNotation.enabled) {
        if (Math.abs(value) >= Math.abs(scientificNotation.value))
          value = value.toExponential();
      } else if (
        !isNaN(value) &&
        !Number.isInteger(value) &&
        !isNaN(floatDigits)
      ) {
        value = toFixedCustom(value, floatDigits);
      }
      return value;
  }
};

const filterAndReorderArray = (reading, options) => {
  const defaultFontColor = getFontColor(options, reading);
  const colors = getAlertColorForArrayValue(reading, false, defaultFontColor);
  let inputValue = reading.value;
  //Filter out non selected indexes and reorder the selected ones
  let reorderedInputValue = [];
  let reorderedColors = [];
  if (options && options.dataSources) {
    const dataSourceOptions = options.dataSources[0];
    if (dataSourceOptions && dataSourceOptions.indexes) {
      dataSourceOptions.indexes.forEach((index) => {
        const value = inputValue[index.index];
        reorderedInputValue.push(value);
        const color = colors[index.index];
        reorderedColors.push(color);
      });
    } else {
      reorderedInputValue = inputValue;
      reorderedColors = colors;
    }
  } else {
    reorderedInputValue = inputValue;
    reorderedColors = colors;
  }
  inputValue = reorderedInputValue.map((value, index) => {
    return (
      <>
        <Text>{value && value.value}</Text>
        {index < reorderedInputValue.length - 1 ? <Text>, </Text> : null}
      </>
    );
  });
  return inputValue;
};

const getMapping = (value, options) => {
  const { valueMapping } = options;
  if (!valueMapping) return null;
  let result = null;
  Array.isArray(valueMapping) &&
    valueMapping.some((mapping) => {
      if (
        (!isNaN(value) &&
          mapping.isRange &&
          value >= mapping.rangeMin &&
          value <= mapping.rangeMax) ||
        mapping.value === value
      ) {
        result = mapping;
        return true;
      }
      return false;
    });
  return result;
};

const getOption = (reading, options) => {
  const value = reading.value;
  let mapping = null;
  const dataSourceOptions = options.dataSources ? options.dataSources[0] : null;
  if (dataSourceOptions && dataSourceOptions.indexes)
    mapping = getMapping(
      value[dataSourceOptions.indexes[0].index] &&
        value[dataSourceOptions.indexes[0].index].value,
      options
    );
  else mapping = getMapping(value[0].value, options);
  return mapping;
};

const DEFAULT_BACKGROUND_COLOR = "#27293a";
const getBackgroundColor = (options, reading) => {
  if (reading) {
    const mapping = getOption(reading, options);
    if (mapping && mapping.backgroundColor) return mapping.backgroundColor;
  }

  if (options && options?.styles?.backgroundColor)
    return options.styles.backgroundColor;
  return DEFAULT_BACKGROUND_COLOR;
};

const convertRGB2Hex = (rgba) => {
  const match = rgba?.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]*)?\)$/);
  if (!match) return rgba;
  const [r, g, b] = match.slice(1, 4).map(Number);
  return `#${[r, g, b].map((c) => c.toString(16).padStart(2, "0")).join("")}`;
};

const DEFAULT_FONT_COLOR = "text.default";
const getFontColor = (options, reading) => {
  if (reading) {
    const mapping = getOption(reading, options);
    if (mapping && mapping.fontColor) return mapping.fontColor;
  }

  if (options && options?.styles?.fontColor) return options.styles.fontColor;
  return DEFAULT_FONT_COLOR;
};

const DEFAULT_FONT_SIZE = "18px";
const getFontSize = (options, reading) => {
  if (reading) {
    const mapping = getOption(reading, options);
    if (mapping && mapping.fontColor) return `${mapping.fontSize}px`;
  }

  if (options && options?.styles?.fontSize)
    return `${options.styles.fontSize}px`;
  return DEFAULT_FONT_SIZE;
};

export class SimpleValueComponent extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     modalOpen: false
  //   };
  // }

  getDataSource(data, options) {
    // TODO: once widgets are properly wire up, pass down only widget specific data
    const filteredByDatasources =
      options &&
      data &&
      data.filter((i) => options.map((o) => o.id).includes(i.dataSource.id));
    const filtered =
      data &&
      data.length > 0 &&
      Array.isArray(filteredByDatasources) &&
      filteredByDatasources.length > 0 &&
      filteredByDatasources[0];
    return filtered;
  }

  getReading(readings) {
    return readings && readings.length > 0 && readings[0];
  }

  renderComponentByUnit(reading, unit) {
    const { options } = this.props;
    let inputValue = reading.value;
    const defaultFontColor = getFontColor(options, reading);
    const isArray = Array.isArray(inputValue);

    //Check if value has mapping
    inputValue.forEach((value, index) => {
      const mapping = getMapping(inputValue[index].value, options);
      if (mapping && mapping.enumeration)
        inputValue[index].value = mapping.enumeration;
    });

    if (isArray) {
      //Value Conversion, Scientific Notation, Float Digits
      inputValue.forEach(
        (input, index) =>
          (inputValue[index].value = convertValue(input.value, options))
      );
    } else {
      inputValue.value = convertValue(inputValue.value, options);
    }

    switch (unit) {
      case "epoch":
      case "sec":
        return (
          <DateTimeComponent
            unixTimestampSec={inputValue[0].value}
            outputFormat={unit}
            options={options}
          />
        );
      default: {
        if (isArray) {
          inputValue = filterAndReorderArray(reading, options);
        }
        break;
      }
    }

    switch (options.visibilityMode) {
      case "alwaysVisible":
        return (
          <Flex flexDirection="column" alignItems="center">
            <Text fontSize={getFontSize(options, reading)}>
              {inputValue} {options && options.showUnit && unit}
            </Text>
            <Flex mt={2} color="text.default">
              <Text>
                {configOptionsGetTimeReferenceValue(options, reading)}
              </Text>
              {/* THIS IS TAKING TOO MUCH SPACE AND SEEMS REDUNDUNT */}
              {/* <Text ml={1}>
                ({configOptionsGetTimeReferenceLabel(options)})
              </Text> */}
            </Flex>
          </Flex>
        );
      case "onHoverVisible":
        return (
          <Tooltip
            placement="right"
            trigger="hover"
            tooltip={`${configOptionsGetTimeReferenceValue(
              options,
              reading
            )} (${configOptionsGetTimeReferenceLabel(options)})`}
          >
            <Text
              fontSize={getFontSize(options, reading)}
              color={`${getAlertColor(reading, defaultFontColor)} !important`}
            >
              {inputValue} {options && options.showUnit && unit}
            </Text>
          </Tooltip>
        );
      default:
        return (
          <Text
            fontSize={getFontSize(options, reading)}
            color={getAlertColor(reading, defaultFontColor)}
          >
            {inputValue} {options && options.showUnit && unit}
          </Text>
        );
    }
  }

  renderComponent(data) {
    const { options } = this.props;
    const reading = this.getReading(data.readings);

    //Overwrite data with free text value
    if (options.textValue)
      return (
        <Text fontSize={getFontSize(options, reading)}>
          {options.textValue}
        </Text>
      );

    if (reading) {
      const unit =
        options.scale?.customUnit ||
        (data.dataSource.units && data.dataSource.units.unit);
      return this.renderComponentByUnit(clone(reading), unit);
    }

    //No data
    return (
      <Text
        style={{
          fontSize: getFontSize(options, reading),
          alignItems: "center",
          whiteSpace: "nowrap"
        }}
      >
        {options.textValue ? options.textValue : `<No Data>`}
      </Text>
    );
  }

  render() {
    const { options, type, showModal, id } = this.props;
    // TODO: simplify scaling config
    const enableScaling = options.scale?.enableScaling?.enableScaling;
    return (
      <TelemetryBaseComponent {...this.props} getData={this.getDataSource}>
        {({ data: _data }) => {
          const data = enableScaling ? scaleSimpleValue(_data, options) : _data;
          return (
            <Flex
              color={convertRGB2Hex(
                getFontColor(options, this.getReading(data.readings))
              )}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              data-testid="simple-value"
              overflow="visible"
              backgroundColor={convertRGB2Hex(
                getBackgroundColor(options, this.getReading(data.readings))
              )}
              position="relative"
            >
              <DragHandle absolute top={4} left={8} />
              <Flex flex={1} mt={1} overflow="visible" color="text.default">
                {options.label && (
                  <Text fontSize={18} bold>
                    {options.label}
                  </Text>
                )}
              </Flex>
              <Absolute
                cursor="pointer"
                padding={2}
                onClick={() => showModal(type, id)}
                right={0}
                top={"-5px"}
                color="text.default"
                data-testid="settings-icon"
              >
                <Icon name={"SettingsMenu"} size={20} id="settings-gear" />
              </Absolute>
              <Flex p={2} overflow="visible">
                {this.renderComponent(data)}
              </Flex>
            </Flex>
          );
        }}
      </TelemetryBaseComponent>
    );
  }
}

SimpleValueComponent.defaultProps = {
  ...DashboardComponents.SimpleValue
};
