import {
  Telecommand,
  TelecommandStack,
  TelecommandStackActionTypes,
  TelecommandStackStatus,
  TelecommandStatus
} from "../models";
import { bindActionCreators } from "redux";
import config from "config/constants";
import { getTelecommandStacksList, updateTelecommandStack } from "../services";
import store from "app/store/store";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus, getFeedbackStatus } from "app/feedback/models";
import { execTelecommand, getExecTelecommand } from "app/telecommand/services";
import { setNonDefaultParams } from "../helpers";
import HttpError from "app/network/utils/HttpError";

export const selectStack = (telecommandStackId: string) => {
  return {
    type: TelecommandStackActionTypes.SetSelectedStack,
    payload: telecommandStackId
  };
};

export const addToStack = (
  telecommand: Telecommand,
  selectedStack: TelecommandStack
) => {
  return {
    type: TelecommandStackActionTypes.AddToStack,
    payload: telecommand,
    nonDefaultParams: setNonDefaultParams(store, telecommand),
    selectedStack: selectedStack
  };
};

export const addCommentToStack = (
  comment: String,
  edit?: String,
  id?: String
) => {
  const selectedStack = store.getState().telecommandStack.selectedStack;
  return {
    type: TelecommandStackActionTypes.AddComment,
    payload: comment,
    selectedStack: selectedStack,
    edit: edit,
    id: id
  };
};

export const copyTelecommand = (telecommand: Telecommand) => {
  const selectedStack = store.getState().telecommandStack.selectedStack;
  return {
    type: TelecommandStackActionTypes.copyTelecommand,
    payload: telecommand,
    nonDefaultParams: setNonDefaultParams(store, telecommand),
    selectedStack: selectedStack
  };
};

export const reorderTelecommands = (
  selectedStackId: string,
  updatedStack: TelecommandStack
) => {
  return {
    type: TelecommandStackActionTypes.ReOrder,
    payload: selectedStackId,
    selectedStack: updatedStack
  };
};

export const removeTelecommand = (telecommand: Telecommand) => {
  const selectedStack = store.getState().telecommandStack.selectedStack;
  return {
    type: TelecommandStackActionTypes.RemoveFromStack,
    payload: telecommand,
    selectedStack: selectedStack
  };
};

export const editTelecommand = (telecommand: any, index: any) => {
  return {
    type: TelecommandStackActionTypes.SetSelectedTC,
    payload: { telecommand, index }
  };
};

export const resetTelecommandStatus = () => {
  const selectedStack = store.getState().telecommandStack.selectedStack;
  return {
    type: TelecommandStackActionTypes.ResetTCStatus,
    selectedStack: selectedStack
  };
};

export const updateTCStatus = (
  telecommandId: any,
  telecommandStackId: any,
  status: any
) => {
  if (!telecommandId) {
    tcStack.toast(
      "Telecommand Status Update Failed: telecommand id missing",
      "ERROR"
    );
    return {};
  }
  return {
    type: TelecommandStackActionTypes.UpdateTCStatus,
    stackId: telecommandStackId,
    telecommandId: telecommandId,
    payload: status
  };
};

export const updateTCStackStatus = (status: any, telecommandStackId: any) => {
  if (!status)
    return {
      type: TelecommandStackActionTypes.UpdateStackStatus,
      stackId: telecommandStackId,
      payload: TelecommandStackStatus.STOPPED
    };
  return {
    type: TelecommandStackActionTypes.UpdateStackStatus,
    stackId: telecommandStackId,
    payload: status
  };
};

export const getTelecommandStacks = () => {
  return async (dispatch: any) => {
    try {
      const { data } = await getTelecommandStacksList();

      const response = Array.isArray(data) ? data : [];

      dispatch({
        type: TelecommandStackActionTypes.GetTelecommandStacks,
        payload: response
      });
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject(e);
    }
  };
};

export const updateTelecommandStackAction = (id: string, data: any) => {
  return async (dispatch: any) => {
    try {
      await updateTelecommandStack(id, data);
      dispatch(getTelecommandStacks());
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveTelecommandStack = () => {
  return async (dispatch: any) => {
    try {
      const selectedStack = store.getState().telecommandStack.selectedStack;
      !selectedStack.id &&
        dispatch(setFeedback("Select TC Stack", FeedbackStatus.ERROR));
      await updateTelecommandStack(selectedStack.id, selectedStack);
      dispatch(setFeedback("TC Stack Saved", FeedbackStatus.SUCCESS));
      dispatch(getTelecommandStacks());
      dispatch(selectStack(selectedStack.id));
    } catch (e) {
      console.log("TURBO ~ saveTelecommandStack ~ e", e);
    }
  };
};

export const showFeedback = (msg: string, level: string, details?: string) => {
  return (dispatch: any) => {
    dispatch(setFeedback(msg, getFeedbackStatus(level), details));
  };
};

export const refetchTCList = (refetch: any) => {
  const tcId = refetch && JSON.parse(refetch).telecommandId;
  if (tcId) {
    return {
      type: TelecommandStackActionTypes.RefetchTC,
      payload: tcId
    };
  }
  return {
    type: TelecommandStackActionTypes.RefetchTC,
    payload: null
  };
};

export const editTelecommandStack = (
  telecommand: Telecommand,
  selectedStack: TelecommandStack
) => {
  return async (dispatch: any): Promise<any> => {
    try {
      const updatedStack = {
        ...selectedStack,
        telecommandList: [
          ...selectedStack.telecommandList,
          {
            telecommand: telecommand.telecommand,
            passageId: telecommand.passageId,
            id: new Date().getTime().toString()
          }
        ]
      };
      await updateTelecommandStack(selectedStack.id, updatedStack);
      dispatch(getTelecommandStacks());
    } catch (error) {
      console.log("TURBO ~ return ~ error", error);
    }
  };
};

export const sendTelecommand = (
  telecommand: Telecommand,
  selectedPassage: any,
  satellite: any,
  sendIndividually = false
): any => {
  return async () => {
    const id = telecommand.id;
    const selectedStack = store.getState().telecommandStack
      .selectedStack as TelecommandStack;
    // const shouldRefetch = store.getState().telecommandStack.refetchTC
    const status = selectedStack.status;
    if (status && status === TelecommandStackStatus.PAUSED) {
      return TelecommandStackStatus.PAUSED;
    }
    try {
      !sendIndividually &&
        tcStack.setStackStatus(
          TelecommandStackStatus.RUNNING,
          selectedStack.id
        );
      const payload =
        selectedPassage && prepareTC(telecommand, selectedPassage);
      const { data: tcSubmit } = await execTelecommand(satellite.id, payload);
      telecommand.id = id;
      if (tcSubmit) {
        tcStack.toast("Telecommand successfully submited", "SUCCESS");
        tcSubmit.response &&
          tcStack.setTCStatus(id, selectedStack.id, tcSubmit.response);
      }
      /*
    retry at 150ms (set in config.timer.refetchInternvalInMS) interval to get updated TC response until TC timeout param, if TC Res is not OK/ERROR, return response obj from execTelecommand as err
    */

      const tcResponse = await retry(
        getExecTelecommand,
        selectedStack.defaultTimeout,
        numOfRetries(telecommand, selectStack),
        tcSubmit
      );

      if (tcResponse) {
        tcStack.setTCStatus(id, selectedStack.id, tcResponse);
        return tcResponse;
      }
      return tcSubmit;
    } catch (error) {
      // if sending fails( 400,503), stop without setting status to allow resumption from failed TC.
      tcStack.toast(
        `Telecommand Sending failed: ${(error as HttpError).status}  - ${
          (error as HttpError).name
        }`,
        "ERROR",
        JSON.stringify(error)
      );
      telecommand.id = id;

      const validationError = {
        statusName: "INVALID",
        level: "ERROR",
        reason: "INVALID"
      };
      tcStack.setTCStatus(id, selectedStack.id, validationError);
      return TelecommandStackStatus.ERROR;
    }
  };
};

// TODO: *Note: use generators for more control over execution*/
const runStack = (selectedPassage: any, satellite: any, resume = false) => {
  return async () => {
    const selectedStack = store.getState().telecommandStack
      .selectedStack as TelecommandStack;
    const tcList = selectedStack.telecommandList;
    !resume && tcStack.reset();
    if (isRunnable(selectedStack)) {
      for (let tc = 0; tc < tcList.length; tc++) {
        try {
          if (isComment(tcList[tc])) {
            // Note: retest it in quality
            tcStack.setStackStatus(
              TelecommandStackStatus.STOPPED,
              selectedStack.id
            );
            continue;
          }
          if (isResume(tcList[tc], resume)) {
            continue;
          }
          const result = await tcStack.sendTelecommand(
            tcList[tc],
            selectedPassage,
            satellite
          );
          console.log("TURBO result sendTelecommand", result);
          if (shouldStop(result)) {
            tcStack.setStackStatus(result.level, selectedStack.id);
            break;
          }
          if (hasFinished(tc, selectedStack)) {
            tcStack.setStackStatus(
              TelecommandStackStatus.STOPPED,
              selectedStack.id
            );
          }
        } catch (error) {
          tcStack.setStackStatus(
            TelecommandStackStatus.ERROR,
            selectedStack.id
          );
          console.log("TURBO ~ onRun ~ error", error);
        }
      }
    }
  };
};

function prepareTC(telecommand: any, selectedPassage: any) {
  telecommand.id && delete telecommand.id;
  telecommand.status && delete telecommand.status;
  telecommand.passageId = selectedPassage.passageID;
  return {
    telecommand: telecommand.telecommand,
    passageId: selectedPassage.passageID
  };
}

function isResume(tc: any, resume: boolean) {
  if (resume) {
    // if resume button is clicked, skip telecommands with SUCCESS or ERROR statuses
    const success = tc.status && tc.status.level === TelecommandStatus.SUCCESS;
    const error = tc.status && tc.status.level === TelecommandStatus.ERROR;
    return success || error;
  }
}

function isComment(tc: any) {
  if (tc.comment) {
    return true;
  }
  return false;
}

function shouldStop(result: any) {
  // stop stack when tc response status is not SUCCESS (covers all error,warning, success response codes)
  if (result.level && result.level === TelecommandStackStatus.SUCCESS) {
    return false;
  }
  return true;
}

function hasFinished(tc: any, selectedStack: TelecommandStack) {
  const lastTCStatusIsNotEmpty =
    selectedStack.telecommandList[selectedStack.telecommandList.length - 1]
      .status !== "";
  const hasReachedLastTC = tc + 1 === selectedStack.telecommandList.length;
  // Note: this addresses the rare case when user has moved an "error" state TC into last place to resume running rest of the stack without resetting the entire stack
  const lastTCHasAlreadyRan = !hasReachedLastTC && lastTCStatusIsNotEmpty;
  return hasReachedLastTC || lastTCHasAlreadyRan;
}

function isRunnable(selectedStack: TelecommandStack) {
  return (
    selectedStack &&
    Array.isArray(selectedStack.telecommandList) &&
    selectedStack.telecommandList.length > 0
  );
}

async function retry(
  func: any,
  defaultTimeout: any,
  retries = 10,
  params: any
): Promise<any> {
  if (!retries) {
    return { statusName: "TIMEOUT", level: "ERROR", reason: "TIMEOUT" };
  }
  try {
    const tcResponse = await func(params.satId, params.id);
    const status = tcResponse.data.basicInfo.response.level;

    if (shouldRetry(status)) {
      await delayInMS(config.timer.refetchIntervalTCStackInMs);
      console.log(`retrying:${retries}`);
      return retry(func, defaultTimeout, retries - 1, params);
    }
    return tcResponse.data.basicInfo.response;
  } catch (e) {
    console.log("err retrying:", e);
  }
}

const getMaxTimeOutFromParams = (telecommand: any, selectedStack: any) => {
  const timeout = telecommand.telecommand.params.timeout
    ? telecommand.telecommand.params.timeout
    : telecommand.telecommand.params.csp &&
      telecommand.telecommand.params.csp.timeout
    ? telecommand.telecommand.params.csp.timeout
    : selectedStack.defaultTimeout;

  return timeout;
};

const numOfRetries = (telecommand: any, selectedStack: any) => {
  const DELAY = config.timer.refetchIntervalTCStackInMs;
  const MAX_TIMEOUT = config.timer.defaultMaxTimeOutTCStackInMs;
  const maxTimeOut =
    getMaxTimeOutFromParams(telecommand, selectedStack) || MAX_TIMEOUT;
  const retries = parseInt((maxTimeOut / DELAY).toFixed());
  return retries;
};

const delayInMS = async (time: any) =>
  await new Promise((resolve) => setTimeout(resolve, time));

export const exportToJson = (telecommandStack: any) => {
  const dataToExport = {
    name: telecommandStack.name,
    satelliteId: telecommandStack.satelliteId,
    description: telecommandStack.description,
    telecommandList: telecommandStack.telecommandList
  };
  const tcString = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(dataToExport)
  )}`;
  const link = document.createElement("a");
  link.href = tcString;
  link.download = `${telecommandStack.name}.json`;
  link.click();
};

const shouldRetry = (status: String) => {
  const successOrError = status === "SUCCESS" || status === "ERROR";
  return !successOrError;
};

// TODO: reimplement runStack using Observable

// const tcResponse$ = new Observable((observer: any) => {
//   const es = new EventSource(`${process.env.REACT_APP_NOTIFICATION_URL}`)
//   es.addEventListener("TELECOMMAND_NOTIFICATION", ((event: MessageEvent) => {
//     const payload = JSON.parse(event.data);
//     if (JSON.parse(payload).message.type === 'TelecommandResponse') {
//       observer.next(JSON.parse(payload).telecommandId);
//     }
//   }) as EventListener);

//   es.onerror = x => observer.error(x);
//   return () => {
//     es.close();
//   };
// });

export const tcStack = bindActionCreators(
  {
    add: addToStack,
    remove: removeTelecommand,
    reset: resetTelecommandStatus,
    copy: copyTelecommand,
    reorder: reorderTelecommands,
    setTCStatus: updateTCStatus,
    setStackStatus: updateTCStackStatus,
    save: saveTelecommandStack,
    edit: editTelecommandStack,
    editTc: editTelecommand,
    set: selectStack,
    list: getTelecommandStacks,
    toast: showFeedback,
    sendTelecommand: sendTelecommand,
    refetch: refetchTCList,
    run: runStack,
    comment: addCommentToStack
  },
  store && store.dispatch
);
