import {
  FetchAllEncryptionKeyAction,
  SetEncryptionKeyStateAction,
  FetchAllEncryptionKeyActionResult,
  KeyManagerActionType,
  SetEncryptionKeyStateResult,
  EncryptionKeyRequestParams,
  SuccessResponse,
  FetchEncryptionKeyActionResult,
  FetchEncryptionKeyAction,
  EncryptionKey,
  FetchAllIVSActionResult,
  FetchIVsAction,
  ActionPayload
} from "../models";
import { Dispatch } from "react";
import {
  fetchAllEncryptionKey,
  fetchAllIVs,
  fetchEncryptionKey,
  setEncryptionKeyState,
  activateKeyOrIV,
  suspendKeyOrIV,
  deactivateKeyOrIV,
  destroyKeyOrIV
} from "../services/encryptionKey";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { store } from "app/store";

export const fetchEncriptionKeyAction = (
  satelliteID: number,
  params: { encryption_key_id: number }
): FetchEncryptionKeyActionResult => {
  return async (dispatch: Dispatch<FetchEncryptionKeyAction>) => {
    try {
      const payload = await fetchEncryptionKey(satelliteID, {
        encryption_key_id: params.encryption_key_id
      });
      dispatch({
        type: KeyManagerActionType.FetchEncryptionKey,
        payload: payload as EncryptionKey
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const fetchAllEncryptionKeyAction = (
  satelliteID: number
): FetchAllEncryptionKeyActionResult => {
  return async (dispatch: Dispatch<FetchAllEncryptionKeyAction>) => {
    try {
      const payload = await fetchAllEncryptionKey(satelliteID);
      dispatch({
        type: KeyManagerActionType.FetchAllEncryptionKey,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const fetchAllIVsAction = (
  satelliteID: number
): FetchAllIVSActionResult => {
  return async (dispatch: Dispatch<FetchIVsAction>) => {
    try {
      const payload = await fetchAllIVs(satelliteID);
      dispatch({
        type: KeyManagerActionType.FetchAllIVs,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve(null);
    }
  };
};

export const setEncryptionKeyStateAction = (
  satelliteID: number,
  params: EncryptionKeyRequestParams
): SetEncryptionKeyStateResult => {
  return async (dispatch: Dispatch<SetEncryptionKeyStateAction>) => {
    try {
      const response = await setEncryptionKeyState(satelliteID, params);

      if ((response as SuccessResponse).status !== 204) {
        store.dispatch(
          setFeedback(
            (response as SuccessResponse)?.data.type || "Error",
            FeedbackStatus.ERROR,
            (response as SuccessResponse)?.data.description ||
              (response as SuccessResponse)?.data.detail
          )
        );
        return Promise.resolve(null);
      }

      dispatch({
        type: KeyManagerActionType.SetEncryptionKeyState,
        payload: params
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.resolve(null);
    }
  };
};

export const activateAction =
  (payload: ActionPayload) => async (dispatch: Dispatch<any>) => {
    try {
      const result = await activateKeyOrIV(payload);
      const err =
        !Array.isArray(result) && result.status === "FAILED"
          ? result?.details
          : null;
      dispatchFeedback(dispatch, "Activation", err, payload.satId);
    } catch (error) {
      dispatch(
        setFeedback(
          `Activation failed`,
          FeedbackStatus.ERROR,
          (error as any).toString()
        )
      );
      console.error("Activation failed:", error);
    }
  };

export const suspendAction =
  (payload: ActionPayload) => async (dispatch: Dispatch<any>) => {
    try {
      const result = await suspendKeyOrIV(payload);
      const err =
        !Array.isArray(result) && result.status === "FAILED"
          ? result.details?.toString()
          : null;
      dispatchFeedback(dispatch, "Suspend", err, payload.satId);
    } catch (error) {
      console.error("SuspendAction failed:", error);
      dispatch(
        setFeedback(
          `Suspend failed`,
          FeedbackStatus.ERROR,
          (error as any).toString()
        )
      );
    }
  };

export const deactivateAction =
  (payload: ActionPayload) => async (dispatch: Dispatch<any>) => {
    try {
      const result = await deactivateKeyOrIV(payload);
      const err =
        !Array.isArray(result) && result.status === "FAILED"
          ? result.details?.toString()
          : null;
      dispatch(fetchAllEncryptionKeyAction(payload.satId));
      dispatch(fetchAllIVsAction(payload.satId));
      dispatchFeedback(dispatch, "Deactivate", err, payload.satId);
    } catch (error) {
      console.error("Deactivate failed:", error);
      dispatch(
        setFeedback(
          `Deactivate failed`,
          FeedbackStatus.ERROR,
          (error as any).toString()
        )
      );
    }
  };

export const destroyAction =
  (payload: ActionPayload) => async (dispatch: Dispatch<any>) => {
    try {
      const result = await destroyKeyOrIV(payload);
      const err =
        !Array.isArray(result) && result.status === "FAILED"
          ? result.details?.toString()
          : null;
      dispatchFeedback(dispatch, "Destroy", err, payload.satId);
    } catch (error) {
      console.error("Destroy failed:", error);
      dispatch(
        setFeedback(
          `Destroy failed`,
          FeedbackStatus.ERROR,
          (error as any).toString()
        )
      );
    }
  };

const dispatchFeedback = (
  dispatch: Dispatch<any>,
  action: string,
  err: any = null,
  satId: number
) => {
  dispatch(fetchAllEncryptionKeyAction(satId));
  dispatch(fetchAllIVsAction(satId));
  dispatch(
    setFeedback(
      `${action} ${err ? "failed" : "successful"}`,
      err ? FeedbackStatus.ERROR : FeedbackStatus.SUCCESS,
      typeof err === "object" ? JSON.stringify(err) : err?.toString() || ""
    )
  );
  if (err) console.error(`${action} failed:`, err);
};
