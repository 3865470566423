import React, { useState, useRef } from "react";
import { NoData } from "./DatasourceHistoryTable";
import { Table, TableHead, TableRow, TableCell, TableBody } from "components";
import { PaginationSimple } from "app/shared";
import { TimeReference } from "app/telemetry/models";
import { DataSourceTableCell } from "./DataSourceTableCell";
import {
  AlertValueStatus,
  AlertValueResponse,
  AlertLevelResponse
} from "app/alert/models";
import { AlertDetail } from "./AlertDetail";
import { Relative } from "primitives";

interface TableState {
  timeReference?: TimeReference;
  selectedSystems?: any[];
  paginationUrl?: string | number | null;
  pageSize?: number;
  from?: string | null;
  to?: string | null;
  selectedDataSources?: any[];
}

interface HistoryTableRendererProps {
  tmData: any;
  pageSize: number;
  setState: (state: TableState) => void;
  timeReference: TimeReference;
}

type MouseCoord = { x: number; y: number };

export interface AlertLevel extends AlertLevelResponse {
  condition: string;
}

export interface Alert extends Omit<AlertValueResponse, "type" | "alertLevel"> {
  type: AlertValueStatus;
  alertLevel: AlertLevel;
}

const TimeForReference = ({ timeReference, timeInfo }: any) => {
  if (timeReference === TimeReference.OnboardTime) {
    return (
      <span>
        {timeInfo.bootCount} | {timeInfo.onboardTimeMillis}
      </span>
    );
  } else if (timeReference === TimeReference.SendUtcTime) {
    return <span>{timeInfo.sendTimestamp}</span>;
  } else {
    return <span>{timeInfo.receiveTimestamp}</span>;
  }
};

/**
 * @description Calculate mouse pointer coordinate in the table bounds
 * @param evt
 * @returns MouseCoord
 */
const getMouseCoord = (evt: React.MouseEvent<HTMLSpanElement>): MouseCoord => {
  try {
    const historyTableContainer = document.getElementById(HISTORY_TABLE_ID);
    if (!historyTableContainer) return { x: 0, y: 0 };
    const parent = historyTableContainer.getBoundingClientRect();
    const x = evt.clientX - parent.left;
    const y = evt.clientY - parent.top;

    return { x, y };
  } catch (e) {
    console.log(e);
    return { x: 0, y: 0 };
  }
};

const HISTORY_TABLE_ID = "history-table-container";

export const HistoryTableRenderer = ({
  tmData,
  pageSize,
  setState,
  timeReference
}: HistoryTableRendererProps) => {
  const [alertData, setAlertData] = useState<{
    alert: Alert;
    coord: MouseCoord;
    parentRow: string;
  } | null>(null);
  const tooltipTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  if (!tmData || (tmData && !tmData.datasourceReading)) {
    return <NoData />;
  }

  const handleTooltip = async (
    evt: React.MouseEvent<HTMLSpanElement>,
    alert: Alert,
    parentRow: string
  ) => {
    evt.stopPropagation();
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    const coord = getMouseCoord(evt);
    await setAlertData({ alert, coord, parentRow });
    tooltipTimeout.current = setTimeout(async () => {
      await setAlertData(null);
    }, 5000);
  };

  const rows = tmData.datasourceReading.data;
  const labels: string[] = Object.values(
    tmData.datasourceReading.dataSourceNames
  );
  const labelsKey = Object.keys(tmData.datasourceReading.dataSourceNames);
  const keys = Object.keys(rows);

  const { nextPageUrl, previousPageUrl } = tmData;

  const rowRenderer = (row: string) => {
    // If the alert detail tooltip is open the row is active and remain highlighted
    const isActiveRow = row === alertData?.parentRow;
    return (
      <TableRow
        key={row}
        style={{
          ...(isActiveRow
            ? { backgroundColor: "rgba(122, 185, 219, 0.2)" }
            : {})
        }}
      >
        <TableCell>
          <TimeForReference
            timeReference={timeReference}
            timeInfo={rows[row].timeInfo}
          />
        </TableCell>
        {Object.entries(rows[row].data).map(([sourceName, source]) => {
          const sourceValue: any = source || {};
          return (
            <DataSourceTableCell
              key={`${row}-${sourceName}`}
              parentRow={row}
              sourceValue={sourceValue}
              handleTooltip={handleTooltip}
            />
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <Relative id={HISTORY_TABLE_ID} onClick={() => setAlertData(null)}>
        <Table>
          {keys.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell>
                  <NoData />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              <TableHead>
                <TableRow bg="fill.0" color="text.white" py={2}>
                  <TableCell
                    position="sticky"
                    top={0}
                    bg="fill.1"
                    width="auto"
                    border={4}
                    borderColor="fill.3"
                  >
                    Time
                  </TableCell>
                  {labels.map((label, idx) => {
                    return (
                      <TableCell
                        position="sticky"
                        top={0}
                        bg="fill.1"
                        width="auto"
                        border={4}
                        borderColor="fill.3"
                        key={labelsKey[idx]}
                      >
                        {label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>{keys.map((key) => rowRenderer(key))}</TableBody>
            </>
          )}
        </Table>
        <AlertDetail
          alert={alertData?.alert || null}
          position={alertData?.coord || null}
        />
      </Relative>

      <PaginationSimple
        pageSize={pageSize}
        nextPageUrl={nextPageUrl}
        previousPageUrl={previousPageUrl}
        onChange={(paginationUrl) => {
          setState({ paginationUrl });
        }}
        onPageSizeChange={(size) =>
          setState({ pageSize: size, paginationUrl: null })
        }
        small={true}
      />
    </>
  );
};
