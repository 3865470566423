import TLEJS from "tle.js";
import ColorConvert from "color-convert";
import { Color } from "cesium";
import {
  SATELLITE_DOT_HAS_PASSAGE_IMAGE_URI,
  SATELLITE_DOT_IMAGE_URI,
  SATELLITE_HAS_PASSAGE_IMAGE_URI,
  SATELLITE_IMAGE_URI
} from "./images";
import { GroundStation } from "app/groundStation";

const tlejs = new TLEJS();

export const isValidTLE = (tleStr: string) => {
  if (tleStr) {
    const matches = tleStr.match(/[^\n]*\n[^\n]*/gi);
    if (!matches) {
      return false;
    }
    const numberOfLines = matches.length;
    return numberOfLines === 2;
  }
  return false;
};

export const parseTLE = (tleStr: string) => {
  return {
    name: tlejs.getSatelliteName(tleStr),
    number: tlejs.getSatelliteNumber(tleStr),
    classification: tlejs.getClassification(tleStr),
    launchYear: tlejs.getIntDesignatorYear(tleStr),
    launchNumber: tlejs.getIntDesignatorLaunchNumber(tleStr),
    launchPiece: tlejs.getIntDesignatorPieceOfLaunch(tleStr),
    epochYear: tlejs.getEpochYear(tleStr),
    epochDay: tlejs.getEpochDay(tleStr),
    epochTimestamp: tlejs.getEpochTimestamp(tleStr),
    firstTimeDerivative: tlejs.getFirstTimeDerivative(tleStr),
    secondTimeDerivative: tlejs.getSecondTimeDerivative(tleStr),
    dragTerm: tlejs.getBstarDrag(tleStr),
    setNumber: tlejs.getTleSetNumber(tleStr),
    checksum1: tlejs.getChecksum1(tleStr),
    inclination: tlejs.getInclination(tleStr),
    rightAscension: tlejs.getRightAscension(tleStr),
    eccentricity: tlejs.getEccentricity(tleStr),
    perigee: tlejs.getPerigee(tleStr),
    meanAnomaly: tlejs.getMeanAnomaly(tleStr),
    meanMotion: tlejs.getMeanMotion(tleStr),
    revolutions: tlejs.getRevNumberAtEpoch(tleStr),
    checksum2: tlejs.getChecksum2(tleStr)
  };
};

export const generateGroundStationEntityDescription = (
  groundStation: GroundStation
) => {
  return `
            <h3>Position</h3>
            <p>Longitude: ${groundStation.coordinates.longitude}</p>
            <p>Latitude: ${groundStation.coordinates.latitude}</p>
            <p>Elevation: ${groundStation.coordinates.elevation}</p>
            <h3>Other</h3>
            <p>Ground Station ID: ${groundStation.groundStationID}</p>
            <p>Provider ID: ${groundStation.providerID}</p>
            <p>Status: ${groundStation.status}</p>
            <p>Type: ${groundStation.type}</p>
            `;
};

export const generateSatelliteEntityDescription = (
  satelliteDescriptionData: any
) => {
  return `<h3>Position</h3>
            <p>Longitude: ${
              satelliteDescriptionData.coordinates.longitude
            } °</p>
            <p>Latitude: ${satelliteDescriptionData.coordinates.latitude} °</p>
            <p>Altitude: ${satelliteDescriptionData.coordinates.altitude} km</p>
            <h3>Other</h3>
            <p>Velocity: ${satelliteDescriptionData.velocity} km/s</p>
            <p>Azimuth: ${satelliteDescriptionData.azimuth} °</p>
            <p>Elevation: ${satelliteDescriptionData.elevation} °</p>
            <h3>TLE</h3>
            <p style="white-space: pre-wrap;">${
              satelliteDescriptionData.tle
            }</p>
            <h3>Decoded TLE</h3>
            <p>Satellite Name: ${satelliteDescriptionData.parsedTle.name}</p>
            <p>Satellite Number: ${
              satelliteDescriptionData.parsedTle.number
            }</p>
            <p>Satellite Classification: ${
              satelliteDescriptionData.parsedTle.classification
            }</p>
            10001     <p>Launch Year: ${
              satelliteDescriptionData.parsedTle.launchYear < 56
                ? `20${satelliteDescriptionData.parsedTle.launchYear}`
                : `19${satelliteDescriptionData.parsedTle.launchYear}`
            }</p>
            <p>Launch Number: ${
              satelliteDescriptionData.parsedTle.launchNumber
            }</p>
            <p>Piece Of The Launch: ${
              satelliteDescriptionData.parsedTle.launchPiece
            }</p>
            <p>Epoch Year: 20${satelliteDescriptionData.parsedTle.epochYear}</p>
            <p>Epoch Day: ${satelliteDescriptionData.parsedTle.epochDay}</p>
            <p>Epoch Timestamp: ${
              satelliteDescriptionData.parsedTle.epochTimestamp
            } ms</p>
            <p>First Time Derivative: ${
              satelliteDescriptionData.parsedTle.firstTimeDerivative
            } orbits/day^2</p>
            <p>Second Time Derivative: ${
              satelliteDescriptionData.parsedTle.secondTimeDerivative
            } orbits/day^3</p>
            <p>Drag Term: ${
              satelliteDescriptionData.parsedTle.dragTerm
            } EarthRadii^-1</p>
            <p>Set Number: ${satelliteDescriptionData.parsedTle.setNumber}</p>
            <p>Checksum 1: ${satelliteDescriptionData.parsedTle.checksum1}</p>
            <p>Inclination: ${
              satelliteDescriptionData.parsedTle.inclination
            } °</p>
            <p>Right Ascension: ${
              satelliteDescriptionData.parsedTle.rightAscension
            } °</p>
            <p>Eccentricity: ${
              satelliteDescriptionData.parsedTle.eccentricity
            }</p>
            <p>Perigee: ${satelliteDescriptionData.parsedTle.perigee} °</p>
            <p>Mean Anomaly: ${
              satelliteDescriptionData.parsedTle.meanAnomaly
            } °</p>
            <p>Mean Motion: ${
              satelliteDescriptionData.parsedTle.meanMotion
            } revs/day</p>
            <p>Number Of Revolutions At Epoch: ${
              satelliteDescriptionData.parsedTle.revolutions
            } revs</p>
            <p>Checksum 2: ${satelliteDescriptionData.parsedTle.checksum2}</p>
            `;
};

export const assignHexColorToCZML = (czml: any, color: string) => {
  if (!czml || !czml[1]) {
    return null;
  }
  if (color && color[0] === "#") {
    color = color.substring(1);
  }
  const rgbaColor = ColorConvert.hex.rgb(color);
  rgbaColor.push(255);
  czml[1].path.material.solidColor.color.rgba = rgbaColor;
  return czml;
};

export const changeCZMLBillboard = (
  czml: any,
  billboardOption: string,
  hasPassage: any
) => {
  if (!czml || !czml[1]) {
    return null;
  }
  switch (billboardOption) {
    case "none":
      czml[1].billboard.show = false;
      break;
    case "dot":
      czml[1].billboard.image = hasPassage
        ? SATELLITE_DOT_HAS_PASSAGE_IMAGE_URI
        : SATELLITE_DOT_IMAGE_URI;
      czml[1].billboard.scale = 0.4;
      break;
    default:
      if (hasPassage) {
        czml[1].billboard.image = SATELLITE_HAS_PASSAGE_IMAGE_URI;
      } else {
        czml[1].billboard.image = SATELLITE_IMAGE_URI;
      }
      break;
  }
  return czml;
};

export const generateCesiumColorFromHex = (color: string) => {
  if (color && color[0] === "#") {
    color = color.substring(1);
  }
  const rgbColor: number[] = ColorConvert.hex.rgb(color);
  const scaledColor: number[] = [];
  //Convert rgb 0-255 scale to 0-1 scale for Cesium.Color
  rgbColor.forEach((param: number) => {
    const aux: number = param / 255;
    scaledColor.push(aux);
  });
  return new Color(scaledColor[0], scaledColor[1], scaledColor[2], 0.5);
};
