import React, { Component } from "react";
import { SatelliteInstanceList } from "./SatelliteInstanceList";
import { SatelliteInstanceCreate } from "./SatelliteInstanceCreate";
import { getSatelliteDefinitions } from "app/satelliteDefinition/services";
import { Box, Heading } from "primitives";
import { Constellation } from "app/constellation/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { SatelliteInstance } from "app/satellite/models";

interface SatelliteInstancesProps {
  constellation: Constellation;
  onChange: () => void;
}

interface SatelliteInstancesState {
  satelliteInstances: SatelliteInstance[];
  satelliteDefinitions: SatelliteDefinition[];
}

export class SatelliteInstances extends Component<
  SatelliteInstancesProps,
  SatelliteInstancesState
> {
  constructor(props: SatelliteInstancesProps) {
    super(props);

    const { satelliteInstances } = props.constellation;
    this.state = {
      satelliteInstances,
      satelliteDefinitions: []
    };
  }

  componentDidMount() {
    getSatelliteDefinitions({ page: 1, perPage: 100 }, { summary: true }).then(
      ({ data }: { data?: SatelliteDefinition[] }) => {
        this.setState({ satelliteDefinitions: data || [] });
      }
    );
  }

  onChange() {
    this.props.onChange();
  }

  render() {
    const { constellation } = this.props;
    const { satelliteDefinitions } = this.state;

    return (
      <div data-testid="SatelliteInstances">
        <Heading>Satellite Instances</Heading>
        <SatelliteInstanceList
          satelliteInstances={constellation?.satelliteInstances}
          satelliteDefinitions={satelliteDefinitions}
          onChange={() => this.onChange()}
        />
        <Box mt={3}>
          <SatelliteInstanceCreate
            onChange={() => this.onChange()}
            constellation={constellation}
            satelliteDefinitions={satelliteDefinitions}
          />
        </Box>
      </div>
    );
  }
}
