import { keyManagerDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import {
  EncryptionKey,
  EncryptionKeyRequestParams,
  SetEncryptionKeyStateResponse,
  ErrorResponse,
  InitializationVector,
  EncryptionKeyOrIV,
  Err,
  KeyOrIVPayload
} from "../models";
import { keyStatusMap } from "../utils/constants";

export const fetchEncryptionKey = async (
  satelliteID: number,
  params: { encryption_key_id: number }
): Promise<EncryptionKey | ErrorResponse | null> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/key/${params.encryption_key_id}`
    );
    return response.data;
  } catch (error) {
    return error as ErrorResponse;
  }
};

export const fetchAllEncryptionKey = async (
  satelliteID: number
): Promise<EncryptionKey[]> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/key`
    );
    return (Array.isArray(response.data) && response.data) || [];
  } catch (error) {
    return [];
  }
};

export const fetchAllIVs = async (
  satelliteID: number
): Promise<InitializationVector[]> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.GET_LIST,
      `${satelliteID}/iv`
    );
    return (Array.isArray(response.data) && response.data) || [];
  } catch (error) {
    return [];
  }
};

export const setEncryptionKeyState = async (
  satelliteID: number,
  params: EncryptionKeyRequestParams
): Promise<SetEncryptionKeyStateResponse> => {
  try {
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/key/${params.encryption_key_id}/${
        keyStatusMap[params.state].endpoint
      }`
    );
    return response;
  } catch (error) {
    return error as ErrorResponse;
  }
};

export const activateKeyOrIV = async (
  payload: KeyOrIVPayload
): Promise<EncryptionKeyOrIV[] | Err> => {
  try {
    const { satId: satelliteID, type, id } = payload;
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/${type}/${id}/activate`,
      { data: {} }
    );
    return response?.status === 204
      ? Array.isArray(response.data)
        ? response.data
        : []
      : { status: "FAILED", details: response?.data || null };
  } catch (error) {
    console.log("activateKeyOrIV ~ error:", error);
    return { status: "FAILED", details: error };
  }
};

export const suspendKeyOrIV = async (
  payload: KeyOrIVPayload
): Promise<EncryptionKeyOrIV[] | Err> => {
  try {
    const { satId: satelliteID, type, id } = payload;
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/${type}/${id}/suspend`
    );
    return response?.status === 204
      ? Array.isArray(response.data)
        ? response.data
        : []
      : { status: "FAILED", details: response?.data || null };
  } catch (error) {
    console.log("suspendKeyOrIV ~ error:", error);
    return { status: "FAILED", details: error };
  }
};

export const deactivateKeyOrIV = async (
  payload: KeyOrIVPayload
): Promise<EncryptionKeyOrIV[] | Err> => {
  try {
    const { satId: satelliteID, type, id } = payload;
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/${type}/${id}/deactivate`
    );
    return response?.status === 204
      ? Array.isArray(response.data)
        ? response.data
        : []
      : { status: "FAILED", details: response?.data || null };
  } catch (error) {
    console.log("suspendKeyOrIV ~ error:", error);
    return { status: "FAILED", details: error };
  }
};

export const destroyKeyOrIV = async (
  payload: KeyOrIVPayload
): Promise<EncryptionKeyOrIV[] | Err> => {
  try {
    const { satId: satelliteID, type, id } = payload;
    const response: any = await keyManagerDataProvider(
      OperationType.CREATE,
      `${satelliteID}/${type}/${id}/destroy`
    );
    return response?.status === 204
      ? Array.isArray(response.data)
        ? response.data
        : []
      : { status: "FAILED", details: response?.data || null };
  } catch (error) {
    console.log("destroyKeyOrIV ~ error:", error);
    return { status: "FAILED", details: error };
  }
};
