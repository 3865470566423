import {
  planningSystemActions,
  PlanningSyatemStoreState,
  PlanningSystemActionType,
  TimelineTypes
} from "../models";
import { timelineEntryToCalendar } from "app/planningSystem/utils/helpers";

const initialState: PlanningSyatemStoreState = {
  oaPagination: null,
  oa: [],
  timelines: null,
  calendarTimeline: []
};

export const planningSystemReducer = (
  state: PlanningSyatemStoreState = initialState,
  action: planningSystemActions
): PlanningSyatemStoreState => {
  switch (action.type) {
    case PlanningSystemActionType.GetOperationActivities:
      return (
        (action.payload && {
          ...state,
          oa: action.payload.oas,
          oaPagination: action.payload.pagination
        }) ||
        state
      );
    case PlanningSystemActionType.GetAllTimeline:
      return (
        (action.payload && { ...state, timelines: action.payload }) || state
      );
    case PlanningSystemActionType.GetPastTimeline:
      return {
        ...state,
        timelines: {
          timelines:
            state.timelines?.timelines.map(
              (t) =>
                (t.timelineType === TimelineTypes.MASTER && {
                  ...t,
                  entries: [...t.entries, ...action.payload]
                }) ||
                t
            ) || []
        },
        calendarTimeline:
          state.calendarTimeline.map(
            (c) =>
              (c.timelineType === TimelineTypes.MASTER && {
                ...c,
                items: [
                  ...c.items,
                  ...timelineEntryToCalendar(
                    action.payload,
                    TimelineTypes.MASTER
                  )
                ]
              }) ||
              c
          ) || []
      };
    case PlanningSystemActionType.GetTimeline: {
      return (
        (action.payload && {
          ...state,
          timelines: {
            timelines: [
              ...(state.timelines?.timelines.filter(
                (t) => t.timelineType !== action.payload?.timelineType
              ) || []),
              action.payload
            ]
          }
        }) ||
        state
      );
    }
    case PlanningSystemActionType.GetCalendarTimeline:
      return (
        (action.payload && { ...state, calendarTimeline: action.payload }) ||
        state
      );

    default:
      return state;
  }
};
